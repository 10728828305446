import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useCreateChartingFilterCategories = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) =>
      await http.post(HTTP_ENDPOINTS.postChartingFilterCategories(), dto),
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.chartingFilterCategories],
        });
        options?.onSuccess?.(...args);
      },
    },
  );
};
