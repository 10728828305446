import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import {
  chargePaymentStripeCardReader,
  fetchAllClinics,
  getCardreaders,
} from "../../../../../../../Actions/Sales/salesActions";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import PaymentFormModal from "../components/SwipeCard/PaymentFormModal";
import PaymentTipsModal from "../components/SwipeCard/PaymentTipsModal";
import { useCardReaderCharge } from "../hooks/SwipeCard/useCardreaderCharge";

const FormSwipeCard = ({
  getCardreaders,
  fetchAllClinics,
  cardReadersList,
  allClinics,
  isTipsApplying,
}) => {
  const { invoice, amountToPay } = useInvoice();
  const { showTipsModal } = usePaymentFlow();

  const [userData] = useState(JSON.parse(localStorage.getItem("userData")));
  const [cardReaderList, setCardReaderList] = useState([]);

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
    selectedCard: yup.object().required("Please select a card reader!"),
  });

  useEffect(() => {
    if (userData?.account?.id) {
      fetchAllClinics();
      getCardreaders(userData.account.id);
    }
  }, [userData]);

  useEffect(() => {
    if (cardReadersList?.length && allClinics?.length) {
      setCardReaderList(getListCardReaders());
    }
  }, [cardReadersList, allClinics]);

  const cardOptions =
    cardReaderList?.length &&
    cardReaderList.map((c) => ({
      label: `${c.serialNumber} (${c.clinicName})`,
      value: c.serialNumber,
      id: c.id,
      collectSignature: c.collectSignature,
      readerIdentifier: c.readerIdentifier,
    }));

  const { form, errors, changeFormValue, isValid, submit } = useChargeForm({
    schema,
    onSubmit: () => {
      let formData = {
        transaction_type: "terminal_emv",
        provider: "stripe",
        pos_device: parseInt(form.selectedCard.id),
        invoice: parseInt(invoice.id),
        account_id: userData.account.id,
        amount: form.amount,
      };
      chargePayment(formData);
    },
    initialValues: {
      amount: amountToPay || "",
      selectedCard: null,
    },
  });

  const { chargePayment, isLoading, isPolling } = useCardReaderCharge(
    form.selectedCard,
  );

  const getListCardReaders = () => {
    const cardReaderTable = cardReadersList
      .map((cardReader) => {
        let getClinicById = allClinics.find(
          (clinic) => clinic.id === cardReader.clinic_id,
        );

        if (getClinicById && cardReader.payworks_status === "active") {
          return {
            serialNumber: cardReader.serial_number,
            clinicName: getClinicById.clinic_name,
            id: cardReader.id,
            isDefault: cardReader.is_default,
            collectSignature: cardReader.collects_signature,
            readerIdentifier: cardReader.reader_identifier,
          };
        }
        if (cardReader.clinic_id === 0) {
          return {
            serialNumber: cardReader.serial_number,
            clinicName: userData.account.name,
            id: cardReader.id,
            isDefault: cardReader.is_default,
            collectSignature: cardReader.collects_signature,
            readerIdentifier: cardReader.reader_identifier,
          };
        }
        return null;
      })
      .filter(Boolean);

    return cardReaderTable;
  };

  const getDefaultCardReader = () => {
    const defaultReader = cardReaderList?.find((cardReader) =>
      Boolean(cardReader.isDefault),
    );
    return {
      label: `${defaultReader?.serialNumber} (${defaultReader?.clinicName})`,
      value: defaultReader?.serialNumber,
      id: defaultReader?.id,
      collectSignature: defaultReader?.collectSignature,
      readerIdentifier: defaultReader?.readerIdentifier,
    };
  };

  useEffect(() => {
    if (cardReaderList?.length) {
      changeFormValue("selectedCard", getDefaultCardReader());
    }
  }, [cardReaderList?.length]);

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          form={form}
          changeFormValue={changeFormValue}
          cardOptions={cardOptions}
          isValid={isValid}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          isLoading={isLoading}
          isPolling={isPolling}
          submit={submit}
          form={form}
          errors={errors}
          changeFormValue={changeFormValue}
          showTipsModal={showTipsModal}
          isTipsApplying={isTipsApplying}
          isValid={isValid}
        />
      )}
    </div>
  );
};

FormSwipeCard.propTypes = {
  getCardreaders: PropTypes.func,
  fetchAllClinics: PropTypes.func,
  cardReadersList: PropTypes.array,
  allClinics: PropTypes.array,
  chargePaymentStripeCardReader: PropTypes.func,
  chargeStripeCardReader: PropTypes.object,
};

const mapStateToProps = (state) => ({
  cardReadersList: state.SalesReducer.cardReadersList,
  allClinics: state.SalesReducer.allClinics,
  chargeStripeCardReader: state.SalesReducer.chargeStripeCardReader,
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getCardreaders, fetchAllClinics, chargePaymentStripeCardReader },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormSwipeCard));
