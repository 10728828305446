import React, { Component } from "react";
import cx from "clsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { Link } from "react-router-dom";
import {
  fetchClinics,
  exportEmptyData,
} from "../../../../Actions/clinicsActions.js";
import { autoScrolling } from "../../../../Utils/services.js";
import Loader from "../../../../Components/Common/Loader.js";
import ReactTooltip from "react-tooltip";
import { withCurrentAccountQuery } from "../../../../api/queries/useAccountQuery.js";
import { ACCOUNT_TIME_FORMATS } from "../../../../consts/api.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel.js";
import { Toggle } from "../../../../shared/Toggle/Toggle.js";
import styles from "./scss/clinics.module.scss";
import ClinicCard from "./components/ClinicCard.js";

class Clinics extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      clinic_name: "",
      contact_no: "",
      address: "",
      clinic_business_hours: [],
      id: userData.user.id,
      tax: "",
      clinicList: [],
      showLoadingText: false,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      scopes: "business_hours",
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    let formData = {
      params: {
        sortorder: "asc",
        term: this.state.term,
        scopes: this.state.scopes,
        context: 'include_inactive',
      },
    };
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      clinic_Create_ClinicBtn: languageData.settings["clinic_Create_ClinicBtn"],
      clinics_Address: languageData.settings["clinics_Address"],
      clinics_Business_Hours: languageData.settings["clinics_Business_Hours"],
      clinics_Contact_Number: languageData.settings["clinics_Contact_Number"],
      clinics_Search: languageData.settings["clinics_Search"],
      clinics_Clinic_Name: languageData.settings["clinics_Clinic_Name"],
      clinics_Tax: languageData.settings["clinics_Tax"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchClinics(formData);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        sortorder: this.state.sortorder,
        term: this.state.term,
        scopes: this.state.scopes,
        context: 'include_inactive',
      },
    };
    this.setState({
      sortorder: this.state.sortorder == "asc" ? "asc" : "desc",
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      clinicList: [],
      showLoader: true,
    });
    autoScrolling(true);
    this.props.fetchClinics(formData);
  };

  onSort = (sortby) => {
    let sortorder = this.state.sortorder === "asc" ? "desc" : "asc";
    let formData = {
      params: {
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
        context: 'include_inactive',
      },
    };
    this.setState({
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      clinicList: [],
    });
    localStorage.setItem("sortOnly", false);
    autoScrolling(true);
    this.props.fetchClinics(formData);
  };
  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "",
          term: this.state.term,
          scopes: this.state.scopes,
          context: 'include_inactive',
        },
      };
      autoScrolling(true);
      this.props.fetchClinics(formData);
    }
  };
  userEdit = (id) => {
    return <div>{this.props.history.push(`/settings/clinic/${id}/edit`)}</div>;
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }
    if (
      nextProps.clinicList != undefined &&
      nextProps.clinicListTime !== prevState.clinicListTime
    ) {
      let returnState = {};
      returnState.clinicList = nextProps.clinicList.data;
      returnState.showLoader = false;
      returnState.showLoadingText = false;
      returnState.clinicListTime = nextProps.clinicListTime;
      return returnState;
    }
    return null;
  }

  shouldComponentUpdate() {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    if (this.state.showLoader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          {/*Clinic Head Start*/}
          <div
            className={cx(
              "memberWalletOuter business-section",
              styles.settingsClinicsPage,
            )}
          >
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer">Clinics</span>
                <form onSubmit={this.handleSubmit} className="searchForm">
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      data-url="/settings/clinics"
                      name="term"
                      placeholder={this.state.clinics_Search}
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <div className="memberRightActions">
                  <Link
                    to="/settings/clinic/create"
                    className="new-blue-btn pull-right"
                  >
                    {" "}
                    {this.state.clinic_Create_ClinicBtn}{" "}
                  </Link>
                </div>
              </div>
            </div>
            {/*Clinic Head END*/}
            <div className={styles.clinicsContainer}>
              {this.state.clinicList !== undefined &&
                this.state.clinicList.map((obj, idx) => {
                  return <ClinicCard obj={obj} idx={idx} key={obj.id} />;
                })}
            </div>

            {this.state.showLoader === false && (
              <div
                className={
                  this.state.clinicList != undefined &&
                  this.state.clinicList.length == 0
                    ? "juvly-section full-width no-record"
                    : " juvly-section full-width no-record no-display"
                }
              >
                <div
                  className=" m-t-10"
                  style={{
                    float: "left",
                    width: "100%",
                    fontSize: "13px",
                    textAlign: "center",
                    marginTop: "0px",
                    padding: "0px",
                  }}
                >
                  {this.state.clinic_No_Record_Found}
                </div>
              </div>
            )}

            <div
              className={
                this.state.showLoadingText
                  ? "loading-please-wait no-margin-top"
                  : "loading-please-wait no-margin-top no-display "
              }
            >
              {this.state.loading_please_wait_text}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.ClinicReducer.action === "CLINIC_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.InventoryReducer?.data?.message] || "Unable to fetch clinics");
      returnState.showLoader = false;
    } else {
      returnState.clinicList = state.ClinicReducer.data;
      returnState.clinicListTime = new Date();
    }
  }
  if (state.ClinicReducer.action === "CLINIC_SEARCH") {
    if (state.ClinicReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.ClinicReducer.data.data;
    }
  }
  if (state.ClinicReducer.action === "SELECTED_CLINIC_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      uiNotification.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.ClinicReducer.data.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClinics,
      exportEmptyData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCurrentAccountQuery(Clinics));
