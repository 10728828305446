import { useEffect, useState } from "react";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function useNavigator({ initialQuestionId, path, history }) {
  const { tCommon } = useAppTranslation.Common();
  const [navHistory, setNavHistory] = useState(history || []);

  const head = navHistory[navHistory.length - 1];
  const canGoBackward = navHistory.length > 1;
  const canGoForward = Boolean(path[head]?.next);

  /**
   * If nextLogicKey is provided - search in nextLogic
   * otherwise - take value from next key
   * @param {number?} nextLogicKey
   */
  const goForward = (nextLogicKey) => {
    if (canGoForward) {
      let nextHistoryNode = path[head].next;
      if (nextLogicKey) {
        nextHistoryNode =
          path[head]?.nextLogic?.[nextLogicKey] || nextHistoryNode;
      }
      if (navHistory.includes(nextHistoryNode)) {
        return uiNotification.error(
          tCommon("fillPatientQuestionnaire.error.infiniteNavigationCycle"),
        );
      }
      if (nextHistoryNode) {
        setNavHistory((prev) => [...prev, nextHistoryNode]);
      }
    }
  };

  const goBackward = () => {
    if (canGoBackward) {
      setNavHistory((prev) => prev.slice(0, -1));
    }
  };

  useEffect(() => {
    if (navHistory.length === 0 && initialQuestionId) {
      setNavHistory([initialQuestionId]);
    }
  }, [navHistory.length, initialQuestionId]);

  return {
    head,
    canGoBackward,
    canGoForward,
    goForward,
    goBackward,
    history: navHistory.map(String),
  };
}
