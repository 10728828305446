import React, { useState, useEffect } from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import { svg } from "../../assets/svg";
import { isNullish } from "../../utilities/general";
import styles from "./Collapse.module.scss";

export function Collapse({
  children,
  className,
  header,
  leftAdornment,
  rightAdornment,
  disableToggleButton = false,
  isOpen: externalIsOpen = true,
}) {
  const [isOpen, setIsOpen] = useState(externalIsOpen);
  const onToggleCollapse = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setIsOpen(externalIsOpen);
  }, [externalIsOpen]);

  return (
    <div className={cx(styles.collapse, className)}>
      <div className={styles.collapseHeader}>
        {!isNullish(leftAdornment) && (
          <div className={styles.leftAdornment}>{leftAdornment}</div>
        )}
        <div className={styles.title}>{header}</div>
        {!isNullish(rightAdornment) && <div>{rightAdornment}</div>}
        <button
          className={styles.rightAdornmentBlock}
          disabled={disableToggleButton}
          onClick={onToggleCollapse}
        >
          <img
            src={svg.chevronDown}
            alt="chevronDown"
            width="15px"
            className={cx(styles.chevron, {
              [styles.chevronUp]: Boolean(isOpen),
            })}
          />
        </button>
      </div>
      {Boolean(isOpen) && <div className={styles.collapseBody}>{children}</div>}
    </div>
  );
}

Collapse.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  header: PropTypes.string,
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
};

Collapse.defaultProps = {
  leftAdornment: undefined,
  rightAdornment: undefined,
  className: undefined,
};
