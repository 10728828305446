import React, { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import cx from "clsx";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { Box } from "../../../../shared/Box/Box";
import { ContentLoader } from "../../../../boxes/ContentLoader/ContentLoader";
import { Button } from "../../../../shared/Button/Button";
import { CreateEditCategoryModal } from "./components/CreateEditCategoryModal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import DeleteCategoryModal from "./components/DeleteCategoryModal";
import { useChartingFilterCategoriesQuery } from "../../../../api/queries/useChartingFilterCategoriesQuery";
import {
  CHARTING_UNCATEGORIZED_CATEGORY_ID,
  DEFAULT_CREATE_EDIT_CATEGORY_ID,
} from "./config";
import { useGuard } from "./hooks/useGuard";
import styles from "./scss/chartFilters.module.scss";
import { Table } from "../../../../shared/Table/Table";
import { useUpdateOrder } from "./hooks/useUpdateOrder";

const createCategoriesOrderSnapshot = (categories) =>
  categories.map((x) => x.id).join();

const ChartFilters = () => {
  useGuard();

  const [createEditCategory, setCreateEditCategory] = useState(null);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();
  const reorder = useUpdateOrder();

  const { isLoading } = useChartingFilterCategoriesQuery({
    onSuccess: (data) => {
      setCategories(data);
    },
  });

  const tableData = categories?.map((category) => ({
    id: category.id,
    categoryName: category.name,
    proceduresCount: category.procedures_count || 0,
    nonDraggable: category.id === CHARTING_UNCATEGORIZED_CATEGORY_ID,
    actionButtons: (() =>
      category.id === CHARTING_UNCATEGORIZED_CATEGORY_ID ? null : (
        <div className={styles.actionButtons}>
          <Button size="small" onClick={() => setCreateEditCategory(category)}>
            {tCommon("label.edit")}
          </Button>
          <Button
            size="small"
            color="error"
            onClick={() => setDeleteCategory(category)}
          >
            {tCommon("label.delete")}
          </Button>
        </div>
      ))(),
  }));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const next = arrayMoveImmutable(categories, oldIndex, newIndex);

    const prevCategoriesSnapshot = createCategoriesOrderSnapshot(categories);
    const nextCategoriesSnapshot = createCategoriesOrderSnapshot(next);

    if (prevCategoriesSnapshot === nextCategoriesSnapshot) {
      return;
    }

    reorder.initiate(
      next
        .map((item) => item.id)
        .filter((x) => x !== CHARTING_UNCATEGORIZED_CATEGORY_ID),
    );

    setCategories(next);
  };

  return (
    <LayoutSettings contentClassName={cx(styles.root)}>
      <EntityHeader
        title={tSettings("clinicalDocumentation.chartFilters.title")}
        right={
          <Button
            size="small"
            onClick={() =>
              setCreateEditCategory({ id: DEFAULT_CREATE_EDIT_CATEGORY_ID })
            }
          >
            {tSettings("clinicalDocumentation.chartFilters.createButton")}
          </Button>
        }
      />
      <Box>
        <ContentLoader isLoading={isLoading}>
          <Table.Sortable
            keyExtractor={(row) => row.id}
            data={tableData || []}
            onSortEnd={onSortEnd}
            cols={[
              {
                data: "Category Name",
                accessor: "categoryName",
              },
              {
                data: "Procedures Count",
                accessor: "proceduresCount",
                width: 200,
              },
              {
                data: "Actions",
                accessor: "actionButtons",
                className: "flex",
              },
            ]}
          />
        </ContentLoader>
      </Box>
      {!!createEditCategory && (
        <CreateEditCategoryModal
          category={createEditCategory}
          onClose={() => setCreateEditCategory(null)}
        />
      )}
      {!!deleteCategory && (
        <DeleteCategoryModal
          selectedCategoryId={deleteCategory.id}
          onClose={() => setDeleteCategory(null)}
          categories={categories}
          isGettingCategories={isLoading}
        />
      )}
    </LayoutSettings>
  );
};

export default ChartFilters;
