import { useUpdateChartingFilterCategoriesOrderMutation } from "../../../../../api/mutations/useUpdateChartingFilterCategoriesOrderMutation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useUpdateOrder() {
  const { mutate } = useUpdateChartingFilterCategoriesOrderMutation({
    onSuccess: () => {
      uiNotification.success("Order updated successfully");
    },
    onError: () => {
      uiNotification.error("Failed to update order. Try again later");
    }
  });

  return {
    initiate: (ids) => {
      mutate({ ids });
    },
  }
}
