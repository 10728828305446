import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import { useCreateChartingFilterCategories } from "../hooks/useCreateChartingFilterCategories";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../../shared/Input/Input";
import { InputError } from "../../../../../shared/InputError/InputError";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import styles from "../scss/chartFilters.module.scss";
import { useEditChargingFilterCategories } from "../hooks/useEditChartingFilterCategories";

export function CreateEditCategoryModal({ onClose, category }) {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();
  const edit = useEditChargingFilterCategories(category?.id);
  const create = useCreateChartingFilterCategories();

  const onSubmit = async ({ name }) => {
    try {
      let res = null;

      if (category?.id) {
        res = await edit.mutateAsync({ name });
      } else {
        res = await create.mutateAsync({ name });
      }

      onClose();
      uiNotification.success(res.data.message);
    } catch (e) {
      uiNotification.error(e.response.data.message);
    }
  };

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    onSubmit,
    initialValues: {
      name: category?.name || "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(
          tSettings(
            "clinicalDocumentation.chartFilters.errors.categoryNameRequired",
          ),
        ),
    }),
  });

  const isLoading = edit.isLoading || create.isLoading;

  return (
    <Modal
      isOpen
      headerNoBorder
      footerNoBorder
      onClose={onClose}
      className={styles.modal}
      footerClassName={styles.modalFooter}
      header={
        <Modal.Title>
          {category?.id
            ? tSettings("clinicalDocumentation.chartFilters.editButton")
            : tSettings("clinicalDocumentation.chartFilters.createButton")}
        </Modal.Title>
      }
      footer={
        <>
          <Button variant="outlined" onClick={onClose} isDisabled={isLoading}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            leftAdornment={
              isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
            isDisabled={isLoading}
          >
            {category?.id ? tCommon("label.edit") : tCommon("label.create")}
          </Button>
        </>
      }
    >
      <InputLabel>
        {" "}
        {tSettings("clinicalDocumentation.chartFilters.categoryName")}
      </InputLabel>
      <Input
        placeholder="Category Name"
        value={values.name}
        onChange={(event) => setFieldValue("name", event.target.value)}
        isError={Boolean(errors.name)}
      />
      {errors.name && <InputError>{errors.name}</InputError>}
    </Modal>
  );
}
