import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../../../consts/api";

export const useEditChargingFilterCategories = (id, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto) =>
      await http.put(HTTP_ENDPOINTS.putCharginFilterCategories(id), dto),
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.chartingFilterCategories],
        });
        options?.onSuccess?.(...args);
      },
    },
  );
};
