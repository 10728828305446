import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cx from "clsx";
import { useSpring, a, animated } from "react-spring";
import {
  getNumberWithOrdinal,
  numberFormat,
  showFormattedDate,
} from "../../../Utils";
import classes from "../sass/membership.module.scss";
import { CrossIcon } from "../../../assets/Icons/CrossIcon";
import DownloadAgreementIcon from "../../../assets/Icons/DownloadAgreementIcon";
import HoldMembershipIcon from "../../../assets/Icons/HoldMembershipIcon";
import ResumeMembershipIcon from "../../../assets/Icons/ResumeMembershipIcon";
import { CancelMembershipIcon } from "../../../assets/Icons/CancelMembershipIcon";
import { Select } from "../../../shared/Select/Select";
import MembershipBadge from "../../Common/MembershipBadge";
import DrawDayStepper from "../DrawDayStepper";
import PaymentHistoryTable from "./PaymentHistoryTable";
import AddMembershipCard from "./AddMembershipCard";
import ConfirmationModal from "../../Appointment/Calendar/ConfirmationModal/ConfirmationModal";
import {
  useCancelMembershipQuery,
  useGetAllActiveUserQuery,
  useUpdateMembershipDetailsSoldBy,
  useValidateCouponCodeQuery,
} from "../../../_legacy/Queries";
import { useMeasure } from "../../../_legacy/Containers/Settings/SidebarHelper";
import { capitalize } from "../../../utilities/general";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { AppTranslation, useAppTranslation } from "../../../i18n/useAppTranslation";

const monthOptions = Array.from(Array(12 + 1).keys())
  .slice(1)
  .map((monthsData) => {
    return (
      <option key={monthsData} value={monthsData}>
        {monthsData}
      </option>
    );
  });

const toggle = {
  marginTop: 0,
  cursor: "pointer",
  verticalAlign: "top",
  display: "inline-block",
};

const daysOptData = Array.from(Array(31 + 1).keys())
  .slice(1)
  .map((monthDay) => {
    return (
      <option key={monthDay} value={monthDay}>
        {monthDay}
      </option>
    );
  });

const Tree = ({
  children,
  styleClass = "new-setting-tabs-li",
  styleLinkClass = "parent_class new-setting-subtabs-a",
  navigateTo,
  activeMenu,
  parentIndex,
}) => {
  const [isOpen, setOpen] = useState(activeMenu);
  const previous = true;
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform, visibility, fontWeight } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      visibility: "hidden",
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      visibility: isOpen ? "visible" : "hidden",
      fontWeight: "300"
    },
  });
  return (
    <ul className={styleClass}>
      <div className={cx(classes.membershipKey, "col-xs-6 p-l-0")}>
        Free Products
      </div>
      {children.length > 0 ?
        <a
          className={styleLinkClass + ' p-l-15'}
          style={{ ...toggle, color: "#000000", opacity: children ? 1 : 0 }}
          onClick={() => setOpen(!isOpen)}
          data-is-parent-sidebar={1}
          data-is-opened={isOpen ? 1 : 0}
          data-parent-index={parentIndex}
        >
          {!navigateTo && isOpen && (
            <span
              style={{ ...toggle, color: "#188bf6" }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-up"
                data-is-parent-sidebar={1}
                data-is-opened={isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          {!navigateTo && !isOpen && (
            <span
              style={{ ...toggle, color: "#188bf6" }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={!isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-down"
                data-is-parent-sidebar={1}
                data-is-opened={!isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          {/*&nbsp;{"Products"}*/}
        </a>
          : " - " }
      <animated.div
        className={opacity == 0 ? "no-display frame-content" : "frame-content"}
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
          visibility,
        }}
      >
        <a.ul style={{ transform, fontWeight }} {...bind} children={children} />
      </animated.div>
    </ul>
  );
};

const showMembershipStatus = (membership) => {
  if (membership?.cancel_type === "provider") {
    return <span>{`Canceled by ${membership.cancel_by || "Provider"}`}</span>;
  }

  if (membership?.cancel_type === "patient") {
    return <span>Canceled by <AppTranslation.Common path="label.patient" /></span>;
  }

  if (membership?.cancel_type === "non_payment") {
    return <span>Canceled for Non-Payment</span>;
  }

  if (membership.cancel_at && membership.cancel_at.length > 0) {
    return <span>Canceled</span>;
  }

  if (membership.membership_on_hold) {
    return <span>On Hold</span>;
  }

  if (membership.marked_for_cancel || membership.marked_for_hold) {
    return (
      <span>
        Active until {showFormattedDate(membership.subscription_valid_upto)}
      </span>
    );
  }

  if (
    membership.active_membership &&
    (membership.type === "future" || membership?.cancel_type === "expired")
  ) {
    return <span>Inactive</span>;
  }

  return <span>Active</span>; 
};

const MembershipsDetails = (props) => {
  const {
    userData,
    changeDrawDayPaymentDetail,
    languageData,
    setSelectedMembership,
    getDrawDayPaymentDetails,
    getMembershipFeeAcordion,
    getMembershipStatus,
    handleDownloadAgreement,
    changeMembershipClinicOption,
    updateMembershipCC,
    updateMembershipHoldStatus,
    changeMembershipDrawDay,
    openLoader,
    closeLoader,
    pastDueInvoiceData,
    selectedMembership,
    patientMemberships,
    dataUpdatedAt,
    clientData,
  } = props;
  const { mutate: cancelMembership } = useCancelMembershipQuery({
    closeLoader,
  });
  const { mutate: getCouponDetails } = useValidateCouponCodeQuery();

  const [feeSchedule, setFeeSchedule] = useState(
    changeDrawDayPaymentDetail?.fee_schedule,
  );

  const [cancelMembershipImmediately, setCancelMembershipImmediately] = useState(false)

  const membershipClinicList = useMemo(
    () => patientMemberships?.data?.data?.clinic_list || [],
    [patientMemberships?.data, dataUpdatedAt],
  );

  const { id: membershipId, membershipIndex } = selectedMembership;
  const membershipData = useMemo(
    () =>
      patientMemberships?.data?.data?.memberships?.find(
        ({ membership_id }) => membership_id === membershipId,
      ) || {},
    [patientMemberships?.data, dataUpdatedAt, membershipIndex],
  );

  const clearentClinic = useMemo(() => {
    const publickey = membershipClinicList?.find((item) => {
      return item?.id === membershipData?.clinic_id;
    })?.publickey;
    return {
      publickey: publickey,
    };
  }, [membershipClinicList, membershipData.clinic_id]);

  const [showDrawDay, setShowDrawDay] = useState(false);
  const [selectedDrawDay, setSelectedDrawDay] = useState(
    membershipData?.draw_day,
  );
  const [showCardSection, setShowCardSection] = useState(false);

  const [membershipChangeClinicId, setMembershipChangeClinicId] = useState({});
  const [showMembershipClinic, setShowMembershipClinic] = useState(false);

  // function used for hold membership

  const [membershipPaymentDetails, setMembershipPaymentDetails] =
    useState(null);

  const [drawMonth, setDrawMonth] = useState(1);

  const [selectedMembershipID, setSelectedMembershipID] = useState("");

  const [pause, setPause] = useState(null);

  const [resume, setResume] = useState(false);
  const [isOpenCancelModal, setOpenCancelModal] = useState(false);
  const [chargeFullFee, setChargeFullFee] = useState(false);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [membershipChangeSoldBy, setMembershipChangeSoldBy] = useState({value: null, label: null, isEdit: false});
  const status =
    membershipData?.membership_id && getMembershipStatus(membershipData);
  const [soldByConfirmationDialog, setSoldByConfirmationDialog] = useState(false);
  const { tBi } = useAppTranslation.BusinessInsights();
  
  const handleChangeDrawDateChange = (value, data, drawMonth, isResume) => {
    setSelectedDrawDay(value);
    getDrawDayPaymentDetails(
      data.clinic_id,
      data.membership_id,
      value,
      data.subscription_started_at,
      drawMonth,
      data.applied_coupon,
      isResume || resume,
      chargeFullFee,
    );
    setMembershipPaymentDetails(true);
  };

  const handleToggleChargeFull = async () => {
    setChargeFullFee(!chargeFullFee);
  };

  useEffect(() => {
    if (!isInitialMount) {
      handleChangeDrawDateChange(selectedDrawDay, membershipData);
    } else {
      setIsInitialMount(false);
    }
  }, [chargeFullFee]);

  const displayDays = (data) => {
    setPause(false);
    setResume(true);
    setSelectedMembershipID(data.membership_id);

    if (data) {
      handleChangeDrawDateChange(data?.draw_day, data, null, true);
    }
  };

  const displayMonths = (membershipData) => {
    setPause(true);
    setResume(false);
    setSelectedMembershipID(membershipData.membership_id);
    handleChangeDrawDateChange(
      membershipData.draw_day,
      membershipData,
      drawMonth,
    )
  };

  // dismissHold resets the pause and resume to indicate no active operation
  const dismissHold = useCallback(() => {
    setPause(false);
    setResume(false);
  }, []);

  // submitResume function submits the resume on the selected membership
  const submitResume = (membership) => {
    const formData = {
      hold_status: 0,
      membership_id: membership.membership_id,
      draw_day: parseInt(membership.draw_day),
      charge_full_fee: Number(chargeFullFee),
    };
    openLoader();
    setPause(null);
    setResume(null);
    updateMembershipHoldStatus(formData);
  };

  const submitHold = () => {
    const formData = {
      hold_status: 1,
      membership_id: selectedMembershipID,
      hold_billing_cycles: parseInt(drawMonth),
    };
    openLoader();
    setPause(false);
    setResume(false);
    updateMembershipHoldStatus(formData);
  };

  const handleChangeCard = () => {
    setShowCardSection(true);
  };

  const changeMembershipClinic = (clinicData) => {
    setShowMembershipClinic(true);
    setMembershipChangeClinicId(clinicData);
  };

  const handleMembershipClinicOption = () => {
    openLoader();
    let formData = {
      membership_id: membershipData?.membership_id,
      clinic_id: membershipChangeClinicId?.value,
    };
    changeMembershipClinicOption(formData);
    setShowMembershipClinic(false);
  };

  const cancelChangeMembershipClinic = () => {
    setShowMembershipClinic(false);
  };

  const handleInputChange = (clinicDetails) => {
    setMembershipChangeClinicId(clinicDetails);
  };

  const handleMemberDrawDay = (membershipId) => {
    openLoader();
    let formData = {
      membership_id: membershipId,
      draw_day: selectedDrawDay,
      charge_full_fee: Number(chargeFullFee)
    };
    changeMembershipDrawDay(formData);
    setMembershipPaymentDetails(null);
    setShowDrawDay(false);
  };

  const isChangeCardAllowed = useMemo(() => {
    const hasMembershipFees =
      membershipData.mothly_membership_fees > 0 ||
      membershipData.yearly_membership_fees > 0;
    const showMembershipSection =
      !status.isMarkedForOrCanceled && hasMembershipFees;

    return showMembershipSection;
  }, [
    membershipData.mothly_membership_fees,
    membershipData.yearly_membership_fees,
    status.isMarkedForOrCanceled,
  ]);

  const handleMembershipClick = (membershipData) => {
    if (membershipData.payment_frequency.toLowerCase() === "monthly") {
      if (showDrawDay) setShowDrawDay(false);

      if (status.isMarkedForOrOnHold) {
        if (status.isOnHold) {
          displayDays(membershipData);
        } else {
          submitResume(membershipData);
        }
      } else {
        changeDrawDayPaymentDetail.fee_schedule = [];
        displayMonths(membershipData);
      }
    }
  };

  function getMembershipTierTypeProducts(newMembershipData) {
    return newMembershipData.reduce((res, obj) => {
      const product_name = obj?.product?.product_name;
      if (product_name) {
        res.push(`${product_name} - ${obj.units} units`);
      }
      return res.map((item) => {
        return <div key={item}>{item}</div>;
      });
    }, []);
  }

  const handleCnfCancelMembership = () => {
    cancelMembership({ memId: isOpenCancelModal, clientID: props.clientID, immediateCancel: cancelMembershipImmediately });
    openLoader();
    setOpenCancelModal(false);
  };

  useEffect(() => {
    !status.isMarkedForOrCanceled &&
      membershipData.type == "immediate" &&
      getDrawDayPaymentDetails(
        membershipData.clinic_id,
        membershipData.membership_id,
        membershipData.draw_day,
        membershipData.subscription_started_at,
        1,
        chargeFullFee,
      );
  }, []);

  useEffect(() => {
    const drawDayPayment = changeDrawDayPaymentDetail?.fee_schedule.filter(element => {
      return Object.keys(element).length !== 0;
    });
    setFeeSchedule(drawDayPayment)
  }, [membershipData, changeDrawDayPaymentDetail?.fee_schedule]);

  const {mutate : updateSoldByUser } = useUpdateMembershipDetailsSoldBy(closeLoader);

  const { data: allActiveUser } =
  useGetAllActiveUserQuery(membershipData?.clinic_id);

  const activeUsers = useMemo(() => {
    return (
      allActiveUser?.data?.data?.map((user) => ({
        ...user,
        label: user.full_name,
        value: user.id,
      })) || []
    );
  }, [allActiveUser?.data?.data]);

  const handleEditOrCloseSoldBy = (isClose) => {
    if (isClose) {
      setMembershipChangeSoldBy({
        value: null,
        label: null,
        isEdit: false
      });
      setSoldByConfirmationDialog(false);
    } else {
      setMembershipChangeSoldBy({
        value: membershipData?.sold_by_user?.id || null,
        label: membershipData?.sold_by_user?.full_name || null,
        isEdit: true
      });
    }
  };
  
const handleChangeSoldBy = (user) => {
  setMembershipChangeSoldBy((prev) => ({...prev, value: user.value, label: user.label, }));
}

  const changeMembershipSoldBy = () => {
      openLoader();
      let formData = {
        membership_id: membershipData?.membership_id,
        sold_by: membershipChangeSoldBy?.value,
      };
      updateSoldByUser(formData);
      handleEditOrCloseSoldBy(true);
  };
  
  const handleConfirmationsoldBy = () => {
    membershipData?.sold_by_user?.id !== membershipChangeSoldBy?.value && setSoldByConfirmationDialog(true);
  }
  return (
    <div
      className={cx(
        classes.root,
        classes.membershipDetails,
        "col-sm-8 patient-right m-b-20",
      )}
    >
      <div className={cx(classes.membershipCard)}>
        <div className={classes.membershipTitle}>
          <div>
            <span className="m-l-1 m-r-1 font-size-23 flex gap-8">
              <MembershipBadge
                color={membershipData?.membership_color}
                displayPage={`${classes.membershipBadgeBlock} bedge-client-profile`}
                onHold={status.isOnHold}
              />
              <span>Memberships Details</span>
            </span>{" "}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setSelectedMembership(null)}
          >
            <CrossIcon />{" "}
          </div>
        </div>
        <hr className={classes.horizontal} />
        <div className={classes.membershipContainer}>
          <div className={cx(classes.membershipSection)}>
            {/* Membership Type */}
            {membershipData.tier_name && (
              <div className="row m-b-15">
                <div className={cx(classes.membershipKey, "col-xs-6")}>
                  Membership Name
                </div>
                <div className="col-xs-6 ">{membershipData?.tier_name}</div>
              </div>
            )}
            {/* Start date */}
            <div className="row m-b-15">
              {membershipData.type === "immediate" && (
                <div className={cx(classes.membershipKey, "col-xs-6 ")}>
                  {"Start Date"}
                </div>
              )}
              {membershipData.type === "future" && (
                <div className={cx(classes.membershipKey, "col-xs-6")}>
                  Will Start On
                </div>
              )}
              <div className="col-xs-6 ">
                {showFormattedDate(membershipData.subscription_started_at)}
              </div>
            </div>
            {/* One Time Setup Fee */}
            <div className="row m-b-15">
              <div className={cx(classes.membershipKey, "col-xs-6")}>
                {languageData.clientprofile_ots_fee}
              </div>
              <div className="col-xs-6 ">
                {numberFormat(
                  membershipData.one_time_membership_setup,
                  "currency",
                )}
              </div>
            </div>

            <div className="row m-b-15">
              <div className={cx(classes.membershipKey, "col-xs-6")}>
                Membership Status
              </div>
              <div className="col-xs-6 ">
                {showMembershipStatus(membershipData)}
              </div>
            </div>

            {/* Discount on all items */}
            {membershipData.membership_tier_discount > 0 && (
              <div className="row m-b-15">
                <div className={cx(classes.membershipKey, "col-xs-6")}>
                  {"Discount On All Items"}
                </div>
                <div className="col-xs-6 ">
                  {numberFormat(
                    membershipData.membership_tier_discount,
                    "decimal",
                  ) + "%"}
                </div>
              </div>
            )}

            {/* Free Products */}

            {membershipData.membership_products.length > 0 && (
              <div className="row m-b-15">
                <Tree
                  children={getMembershipTierTypeProducts(
                    membershipData?.membership_products,
                  )}
                  styleClass="col-sm-12 "
                />
              </div>
            )}
            {membershipData.applied_coupon && (
              <div className="row m-b-15">
                <div className={cx(classes.membershipKey, "col-xs-6  ")}>
                  {languageData.label_coupon_applied}
                </div>
                <div className="col-xs-6 ">
                  {membershipData.applied_coupon} (
                  {numberFormat(membershipData.total_discount, "currency")})
                </div>
              </div>
            )}
          </div>
          <div className={cx(classes.membershipSection)}>
            {/* # clinic */}
            {membershipChangeClinicId?.name ||
              (membershipData.membership_clinic_name && (
                <div className="row m-b-15">
                  <div className={cx(classes.membershipKey, "col-xs-6  ")}>
                    Clinic
                  </div>
                  {!showMembershipClinic ? (
                    <div className="col-xs-6  ">
                      {membershipData.membership_clinic_name}
                      {!status.isMarkedForOrCanceled && (
                        <a
                          className="easy-link changeClinic font-w-400"
                          onClick={() =>
                            changeMembershipClinic({
                              id: membershipData.clinic_id,
                              label: membershipData.membership_clinic_name,
                            })
                          }
                        >
                          Edit
                        </a>
                      )}
                    </div>
                  ) : (
                    <div className="col-xs-6 ">
                      <Select
                        value={membershipChangeClinicId}
                        onChange={handleInputChange}
                        options={
                          membershipClinicList?.map((i) => ({
                            value: i.id,
                            label: i.name,
                          })) || []
                        }
                        isSearchable
                        placeholder="Select"
                        name="membershipChangeClinicId"
                        size="small"
                      />

                      <div className={cx(classes.flexBox, "m-t-20")}>
                        <div
                          className={classes.saveButton}
                          onClick={handleMembershipClinicOption}
                        >
                          Save
                        </div>
                        <div
                          onClick={cancelChangeMembershipClinic}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

            {/* Valid Upto */}
            <div className="row m-b-15">
              <div className={cx(classes.membershipKey, "col-xs-6")}>
                {"Subscription Valid Until"}
              </div>
              <div className="col-xs-6 ">
                {showFormattedDate(membershipData.subscription_valid_upto)}
              </div>
            </div>
            {/* Membership Fee */}
            <div className="row m-b-15">
              <div className={cx(classes.membershipKey, "col-xs-6")}>
                {languageData?.clientprofile_membership_fee}
              </div>
              {membershipData.payment_frequency.toLowerCase() === "monthly" && (
                <div className="col-xs-6">
                  {getMembershipFeeAcordion(membershipData)}
                </div>
              )}
              {membershipData.payment_frequency.toLowerCase() === "yearly" && (
                <div className="col-xs-6 ">
                  {getMembershipFeeAcordion(membershipData)}
                </div>
              )}
            </div>
            {/* Payment Frequency */}
            {membershipData.payment_frequency && (
              <div className="row m-b-15">
                <div className={cx(classes.membershipKey, "col-xs-6")}>
                  Payment Frequency
                </div>
                <div className="col-xs-6 ">
                  {capitalize(membershipData.payment_frequency)}
                </div>
              </div>
            )}
            {/* Sold By */}
            {membershipData && (
              <div className="row m-b-15">
                <div className={cx(classes.membershipKey, "col-xs-6")}>
                  Sold By
                </div>
                {!membershipChangeSoldBy?.isEdit ? (
                    <div className="col-xs-6  ">
                      {membershipData?.sold_by_user?.full_name || "N/A"}
                      {!status.isMarkedForOrCanceled && (
                        <a
                          className="easy-link changeClinic font-w-400"
                          onClick={() => handleEditOrCloseSoldBy(false)}
                        >
                          Edit
                        </a>
                      )}
                    </div>
                  ) : (
                    <div className="col-xs-6 ">
                      <Select
                        value={membershipChangeSoldBy}
                        onChange={handleChangeSoldBy}
                        options={activeUsers}
                        isSearchable
                        placeholder="Select"
                        name="membershipChangeClinicId"
                        size="small"
                      />

                      <div className={cx(classes.flexBox, "m-t-20")}>
                        <button
                          className={classes.saveButton}
                          onClick={()=> handleConfirmationsoldBy()}
                          disabled={membershipData?.sold_by_user?.id === membershipChangeSoldBy?.value}
                        >
                          Save
                        </button>
                        <button
                          onClick={() => handleEditOrCloseSoldBy(true)}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                
              </div>
            )}
            {/* DRAW DAY CHANGES START */}
            {membershipData.draw_day > 0 &&
              membershipData.payment_frequency.toLowerCase() === "monthly" &&
              membershipData.type === "immediate" && (
                <div className="row m-b-15">
                  <div className={cx(classes.membershipKey, "col-xs-6")}>
                    {"Draw Day"}
                  </div>

                  <div className="col-xs-6  ">
                    {showDrawDay ? (
                      <>
                        <select
                          className={cx(classes.selectField, "simpleSelect")}
                          onChange={(e) =>
                            handleChangeDrawDateChange(
                              e.target.value,
                              membershipData,
                            )
                          }
                          name="selectedDrawDay"
                          value={selectedDrawDay}
                        >
                          {daysOptData}
                        </select>
                      </>
                    ) : (
                      <>
                        {getNumberWithOrdinal(membershipData.draw_day)}{" "}
                        {languageData.clientprofile_of_every_month}
                        {!status.isMarkedForOrCanceled && (
                          <a
                            className="easy-link changeClinic font-w-400"
                            onClick={() => {
                              setSelectedDrawDay(membershipData.draw_day);
                              changeDrawDayPaymentDetail.fee_schedule = [];
                              dismissHold();
                              setShowDrawDay(true);
                            }}
                          >
                            Edit
                          </a>
                        )}
                      </>
                    )}
                  </div>

                  {showDrawDay &&
                    membershipPaymentDetails &&
                    changeDrawDayPaymentDetail && (
                      <div className="col-sm-8 m-b-10 m-t-10 ">
                        {(feeSchedule?.[0]?.pro_rated_days > 0 || chargeFullFee) &&
                            <div className={classes.waiveSetupFeeCheckbox} onClick={handleToggleChargeFull} style={{fontSize: "14px"}}>
                              <input
                                  type="checkbox"
                                  name="waive-setup-fee"
                                  checked={chargeFullFee}
                                  className="filled-checkbox mr-10"
                              />
                              <span>Charge Full Membership Fee</span>
                            </div>
                        }
                        {feeSchedule?.map((item, idx) => {
                          return (
                            <div key={item.payment_date}>
                              <DrawDayStepper
                                item={item}
                                idx={idx}
                                timelineData={feeSchedule}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  {showDrawDay && (
                    <div className="col-xs-6 ">
                      <div className={cx(classes.flexBox)}>
                        <div
                          className={classes.saveButton}
                          onClick={() =>
                            handleMemberDrawDay(membershipData.membership_id)
                          }
                        >
                          Save
                        </div>
                        <div
                          onClick={() => {
                            setMembershipPaymentDetails(false);
                            setShowDrawDay(false);
                          }}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            {/* DRAW DAY CHANGES END */}

            {membershipData.card_details &&
              membershipData.card_details !== "--" && (
                <div className="row m-b-15">
                  <div className={cx(classes.membershipKey, "col-xs-6")}>
                    <AppTranslation.Common path="label.patient" /> Card
                  </div>
                  <>
                    {!showCardSection ? (
                      <div className="col-xs-6  ">
                        {membershipData.card_details}
                        {isChangeCardAllowed && (
                          <a
                            className="easy-link changeClinic font-w-400"
                            onClick={() => handleChangeCard()}
                          >
                            Edit
                          </a>
                        )}
                      </div>
                    ) : (
                      <div className="col-xs-12">
                        <AddMembershipCard
                          openLoader={openLoader}
                          closeLoader={closeLoader}
                          clientData={clientData}
                          clearentClinic={clearentClinic}
                          clientID={membershipData.clinic_id}
                          selectedMembershipID={membershipData.membership_id}
                          userData={userData}
                          index={selectedMembership?.membershipIndex}
                          languageData={languageData}
                          updateMembershipCC={updateMembershipCC}
                          hideShowCardOption={() => setShowCardSection(false)}
                          selectedClinicId={membershipData.clinic_id}
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          {(membershipData.download_agreement_enabled === 1 ||
            membershipData.download_agreement_enabled === false) && (
            <div
              onClick={() =>
                handleDownloadAgreement(membershipData.membership_id)
              }
              className={classes.downloadAgreement}
            >
              <span className="m-r-10 m-t-7">
                <DownloadAgreementIcon />
              </span>
              <span>Download Agreement</span>
            </div>
          )}
          {!status.isMarkedForOrCanceled && (
            <div
              onClick={() => setOpenCancelModal(membershipData.membership_id)}
              className={classes.cancelMembership}
            >
              <span className="m-r-10 m-t-7">
                <CancelMembershipIcon />
              </span>
              <span>{languageData.clientprofile_cancel_membership}</span>
            </div>
          )}
          {!status.isMarkedForOrCanceled &&
            membershipData.type === "immediate" && (
              <div>
                <div
                  onClick={() => handleMembershipClick(membershipData)}
                  className={
                    membershipData.payment_frequency.toLowerCase() === "monthly"
                      ? classes.holdMembership
                      : "no-display"
                  }
                >
                  {status.isMarkedForOrOnHold ? (
                    <>
                      <span className="m-r-10 m-t-7">
                        <ResumeMembershipIcon />
                      </span>
                      <span>Resume Membership</span>
                    </>
                  ) : (
                    <>
                      {" "}
                      <span className="m-r-10 m-t-7">
                        <HoldMembershipIcon />
                      </span>
                      <span>Hold Membership </span>
                    </>
                  )}
                </div>
                {resume && membershipData.payment_frequency.toLowerCase() == "monthly" && (
                  <div className="simpleField inlineField">
                    {(feeSchedule?.[0]?.pro_rated_days > 0 || chargeFullFee) &&
                        <div className={classes.waiveSetupFeeCheckbox} onClick={handleToggleChargeFull}>
                          <input
                              type="checkbox"
                              name="waive-setup-fee"
                              checked={chargeFullFee}
                              className="filled-checkbox mr-10"
                          />
                          <span>Charge Full Membership Fee</span>
                        </div>
                    }
                    <div className={cx(classes.selectMonth)}>
                      {"Select draw day"}
                    </div>
                    <select
                      className={cx(classes.selectField, "simpleSelect")}
                      onChange={(e) =>
                        handleChangeDrawDateChange(
                          e.target.value,
                          membershipData,
                        )
                      }
                      name="selectedDrawDay"
                      value={selectedDrawDay}
                    >
                      {daysOptData}
                    </select>
                  </div>
                )}
                {pause && membershipData.payment_frequency.toLowerCase() == "monthly" && (
                  <div className="simpleField inlineField">
                    <div className={cx(classes.selectMonth)}>
                      {"Select Months"}
                    </div>
                    <select
                      className={cx(classes.selectField, "simpleSelect")}
                      onChange={(e) => {
                        setDrawMonth(e.target.value);
                        handleChangeDrawDateChange(
                          membershipData.draw_day,
                          membershipData,
                          e.target.value,
                        );
                      }}
                      name="drawMonth"
                      value={drawMonth}
                    >
                      {!drawMonth && <option value=""></option>}
                      {monthOptions}
                    </select>
                  </div>
                )}
                <div>
                  {(resume || pause) && changeDrawDayPaymentDetail && (
                    <div className="col-xs-6 ">
                      {feeSchedule?.map((item, idx) => {
                        return (
                          <div key={item.payment_date}>
                            <DrawDayStepper
                              item={item}
                              idx={idx}
                              timelineData={feeSchedule}
                            />
                          </div>
                        );
                      })}

                      <div className={cx(classes.flexBox, "m-t-20")}>
                        <div
                          className={cx(classes.saveButton, "m-r-50")}
                          onClick={() => {
                            if(resume) membershipData.draw_day = selectedDrawDay;
                            resume ? submitResume(membershipData) : submitHold()
                          }
                          }
                        >
                          Save
                        </div>
                        <div
                          onClick={dismissHold}
                          className={cx(classes.cancelButton, "m-l-30")}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
      {membershipData?.monthly_membership_invoices.length > 0 && (
        <PaymentHistoryTable
          pastDueInvoiceData={pastDueInvoiceData}
          data={membershipData}
          openLoader={openLoader}
          closeLoader={closeLoader}
        />
      )}
      {isOpenCancelModal && (
        <ConfirmationModal
          isOpen={isOpenCancelModal}
          confirmFn={handleCnfCancelMembership}
          cancelFn={() => setOpenCancelModal(false)}
          message={
            membershipData.payment_frequency.toLowerCase() === "yearly"
              ? languageData.clientprofile_cancel_yearly_membership_confirm_message
              : languageData.clientprofile_cancel_monthly_membership_confirm_message
          }
          confirmMessage="Yes"
          cancelMessage="No"
          isCancelMembership
          cancelMembershipImmediately={cancelMembershipImmediately}
          setCancelMembershipImmediately={setCancelMembershipImmediately}
        />
      )}
      {
        soldByConfirmationDialog && (
          <ConfirmModal
            isOpen={soldByConfirmationDialog}
            onCancel={() => handleEditOrCloseSoldBy(true)}
            onClose={() => handleEditOrCloseSoldBy(true)}
            onConfirm={changeMembershipSoldBy}
          >
            {tBi("membershipDetails.confirmationTextSoldBy")}
          </ConfirmModal>
        )
      }

    </div>
  );
};

export default MembershipsDetails;
