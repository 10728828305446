import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { SALES_CHURN_REPORTS_REASON_FILTER_KEYS } from "../../../../../../api/queries/useSalesChurnReportsQuery";
import { selectOptionSchema } from "../../../../../../shared/Select/Select.schemas";
import { hasLength } from "../../../../../../utilities/general";
import { useMembershipTiersQuery } from "../../../../../../api/queries/useMembershipTiersQuery";
import { ReportModalFilter } from "../../../../components/ReportModalFilter/ReportModalFilter";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

const schema = yup.object({
  clinics: yup.array().of(selectOptionSchema),
  tiers: yup.array().of(selectOptionSchema),
  churnReason: yup.string(),
});

const initialValues = {
  clinics: [],
  tiers: [],
  churnReason: "",
};

export function ModalFilter({ isOpen, onClose, filter, clinics }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: tiers, isLoading: isTiersLoading } = useMembershipTiersQuery();

  const handleApply = () => {
    filter.update(values);
    onClose();
  };

  const { values, setFieldValue, dirty, handleSubmit, setValues } = useFormik({
    onSubmit: handleApply,
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filter.value,
    },
  });

  const clinicOptions = (clinics || []).map((clinic) => ({
    label: clinic.clinic_name,
    value: clinic.id,
  }));

  const tiersOptions = (tiers || []).map((tier) => ({
    label: tier.name,
    value: tier.id,
  }));

  const hasCaliforniaClinic = (clinics || []).some(
    (clinic) => (clinic.clinic_state || "").toUpperCase() === "CA",
  );

  const churnReasonOptions = [
    {
      label: tCommon("label.any"),
      value: initialValues.churnReason,
    },
    {
      label: tBi("membershipChurnReport.filter.reason.provider"),
      value: SALES_CHURN_REPORTS_REASON_FILTER_KEYS.provider,
    },
    ...(hasCaliforniaClinic
      ? [
          {
            label: "Canceled by " + tCommon("label.patient"),
            value: SALES_CHURN_REPORTS_REASON_FILTER_KEYS.patient,
          },
        ]
      : []),
    {
      label: tBi("membershipChurnReport.filter.reason.non_payment"),
      value: SALES_CHURN_REPORTS_REASON_FILTER_KEYS.nonPayment,
    },
    {
      label: tBi("membershipChurnReport.filter.reason.expired"),
      value: SALES_CHURN_REPORTS_REASON_FILTER_KEYS.expired,
    },
  ];

  return (
    <ReportModalFilter
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={!dirty}
      isFilterTouched={Object.values(values).some(hasLength)}
      onReset={() => setValues(initialValues)}
    >
      <div>
        <InputLabel>{tCommon("label.clinics")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.clinics}
          onChange={(options) => setFieldValue("clinics", options)}
          options={clinicOptions}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.membershipName")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.tiers}
          onChange={(options) => setFieldValue("tiers", options)}
          options={tiersOptions}
          isLoading={isTiersLoading}
        />
      </div>
      <div>
        <InputLabel>
          {tBi("membershipChurnReport.filter.reason.label")}
        </InputLabel>
        <Select
          value={churnReasonOptions.find((i) => i.value === values.churnReason)}
          onChange={(option) => setFieldValue("churnReason", option.value)}
          options={churnReasonOptions}
        />
      </div>
    </ReportModalFilter>
  );
}

ModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};
