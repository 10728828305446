import { useQualiphyResendExamMutation } from "../../../../../api/qualiphy/useQualiphyResendExamMutation";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { extractApiError, extractApiErrorData } from "../../../../../utilities/api";
import { capitalize } from "../../../../../utilities/general";

export function useResend({ onSuccess }) {
  const { tCommon } = useAppTranslation.Common();

  const { mutate, isLoading } = useQualiphyResendExamMutation({
    onSuccess,
    onError: (e) => {
      const msg = extractApiError(e);
      const data = extractApiErrorData(e);

      uiNotification.error(
        data
          ? capitalize(data)
          : tCommon([`apiError.qualiphy.${msg}`, "error.qualiphy.resend"]),
      );
    },
  });

  return {
    resend: mutate,
    isResending: isLoading,
  };
}
