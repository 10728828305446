export var TABLE_DOM_ID = "Shared-Table-Sortable__table";
export var DEFAULT_TABLE_WIDTH = 100;
export var BASE_WIDTH = 900;
export var DRAGGABLE_COL_WIDTH = 40;
export var DEFAULT_PRESS_DELAY = 100;

export var DRAGGABLE_COL = {
  data: "",
  accessor: Symbol("draggable"),
};
