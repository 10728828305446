import { useMemo } from "react";
import { useSubmit } from "../../../hooks/useSubmit";
import {
  buildIdToAdaptedQuestionMap,
  buildPath,
  questionsAdapter,
} from "../../../utilities";
import { useAnswers } from "./helpers/useAnswers";
import { useNavigator } from "./helpers/useNavigator";
import { extractQuestionnaireStatus } from "../../../../../utilities/api";
import { pick } from "../../../../../utilities/fp";

export function useQuestionnaire({ patientQuestionnaire, onSuccess }) {
  const submit = useSubmit();

  const adaptedQuestions = useMemo(
    () => questionsAdapter(patientQuestionnaire),
    [patientQuestionnaire],
  );

  const [answers, getUpdateHandler] = useAnswers(patientQuestionnaire);

  const idToAdaptedQuestionMap = useMemo(
    () => buildIdToAdaptedQuestionMap(adaptedQuestions),
    [adaptedQuestions],
  );

  const navigator = useNavigator(
    buildPath(adaptedQuestions, patientQuestionnaire?.step_history),
  );

  const currentQuestion = idToAdaptedQuestionMap[navigator.head];
  const currentAnswer = answers?.[currentQuestion.id];

  const onNext = (nextAnswers) => {
    const headAnswer = nextAnswers[navigator.head];

    const nextLogicKey = idToAdaptedQuestionMap?.[
      navigator.head
    ]?.choices?.find((x) => x.text === headAnswer?.choices?.[0])?.id;

    if (navigator.canGoForward) {
      navigator.goForward(nextLogicKey);
    } else {
      submit
        .initiate(pick(navigator.history)(nextAnswers), idToAdaptedQuestionMap)
        .then(() => {
          onSuccess?.();
        });
    }
  };

  const onFinishLater = (nextAnswers) => {
    submit
      .initiate(
        pick(navigator.history)(nextAnswers),
        idToAdaptedQuestionMap,
        navigator.history,
      )
      .then(() => {
        onSuccess?.();
      });
  };

  const updateAnswer = getUpdateHandler(currentQuestion?.id);

  return {
    currentQuestion,
    currentAnswer,
    navigator: {
      canGoBackward: navigator.canGoBackward,
      canGoForward: navigator.canGoForward,
      goBackward: navigator.goBackward,
    },
    updateAnswer,
    onNext,
    onFinishLater,
    isSubmitting: submit.isLoading,
    questionnaireStatus: extractQuestionnaireStatus(patientQuestionnaire || {}),
  };
}
