/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import cx from "clsx";
import { cloneDeep } from "lodash";
import validator from "validator";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { tCommon, tSales } from "../../i18n/useAppTranslation.js";
import {
  getInvoiceDetails,
  getPaymentHistory,
  sendAndDownloadInvoice,
  sendAndDownloadRefundReceipt,
  getRefundDetails,
  payRefund,
  changeUserID,
  exportEmptyData,
  saveAndSendEmail,
  refundIssue,
  voidAndRefund,
  editTipsPerItem,
} from "../../Actions/Invoices/invoiceActions.js";
import {
  capitalizeFirstLetter,
  numberFormat,
  displayName,
  showFormattedDate,
  isNumber,
  checkIfPermissionAllowed,
  getCurrencySymbol,
} from "../../Utils/services.js";
import defLogo from "../../_legacy/images/logo.png";
import { unwrapOr } from "../../utilities/general.js";
import { PAYMENT_MODE_KEYS, REFUND_TO_KEYS } from "./InvoiceDetails.consts.js";
import { DeleteInvoice } from "./components/DeleteInvoice/DeleteInvoice.js";
import { ChangeClinic } from "./components/ChangeClinic/ChangeClinic.js";
import { InputCurrency } from "../../shared/InputCurrency/InputCurrency.js";
import { uiNotification } from "../../services/UINotificationService.js";
import CancelPendingInvoice from "./components/CancelPendingInvoice/CancelPendingInvoice.js";
import { AlertBox } from "../../shared/AlertBox/AlertBox.js";
import classes from "./InvoiceDetails.module.scss";
import RepeatMdRedemptions from "./components/RepeatMd/RepeatMdRedemptions.js";
import { shortenClinicName } from "../../helpers/general";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.invoiceItem = React.createRef();
    this.state = {
      backURLType: this.props.match.params.actionType
        ? this.props.match.params.actionType
        : "clients",
      clientID: this.props.match.params.clientID,
      invoiceID: this.props.match.params.invoiceID,
      showLoader: false,
      globalLang: languageData.global,
      salesLang: languageData.sales,
      invoiceData: [],
      showPaymentHistory: false,
      showRefund: false,
      text_reason_for_refund: "",
      refundReasonClass: "row no-display",
      refund_type: "",
      isFullRefundPossibble: true,
      invoiceActionType: "",
      receiptActionType: "",
      selectedPayments: [],
      cash_amount: "",
      cc_amount: "",
      wallet_amount: "",
      reasonAreaClass: "setting-textarea-box",
      cashAmountClass: "setting-input-box",
      ccAmountClass: "setting-input-box",
      walletAmountClass: "setting-input-box",
      selectAReasonClass: "setting-select-box",
      selectPaymentClass: "",
      selectedProvider: [],
      showNoEmailError: false,
      emailInputClass: "setting-input-box",
      pat_email_id: "",
      payment_refund_type: "default",

      showRefundAndVoidOptions: false,
      void_type: "refund_full_and_void",

      care_credit_amount: "",
      greensky_amount: "",
      careCreditAmountClass: "setting-input-box",
      greenskyAmountClass: "setting-input-box",
      isTouchMD: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
      refundInvoiceId: this.props.match.params.invoiceID,
      tipsPerItem: {},
      tipEditMode: false,
      gift_card_amount: null,
    };

    window.onscroll = () => {
      return false;
    };

    this.props.exportEmptyData({});
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
  }

  fetchInvoiceDetails = (invoiceId, clientId) => {
    this.setState({ showLoader: true });
    this.props
      .getInvoiceDetails(invoiceId, clientId)
      .catch((res) => {
        uiNotification.error(this.state.globalLang[res.message]);
      })
      .finally(() => this.setState({ showLoader: false }));
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.invoiceData !== undefined &&
      props.invoiceData.status === 200 &&
      props.invoiceData.data !== state.invoiceData
    ) {
      let selectedProvider = {};

      if (
        props.invoiceData.data &&
        props.invoiceData.data.invoice_data &&
        props.invoiceData.data.invoice_data &&
        props.invoiceData.data.invoice_data.pos_invoice_items &&
        props.invoiceData.data.invoice_data.pos_invoice_items.length > 0
      ) {
        props.invoiceData.data.invoice_data.pos_invoice_items.map((obj) => {
          if (
            props.invoiceData.data &&
            props.invoiceData.data.all_employees &&
            props.invoiceData.data.all_employees.length > 0
          ) {
            props.invoiceData.data.all_employees.map((iobj) => {
              if (obj.user_id.toString() === iobj.id.toString()) {
                selectedProvider[obj.id] = {
                  value: iobj.id,
                  label: displayName(iobj),
                };
              }
            });
          }
        });
      }

      document.body.style.overflow = "";
      return {
        invoiceData: props.invoiceData.data,
        showLoader: false,
        showPaymentHistory: false,
        selectedProvider: selectedProvider,
        showRefund: false,
        showRefundAndVoidOptions: false,
        tipsPerItem: props.tipsPerItem,
      };
    }

    if (
      props.historyData !== undefined &&
      props.historyData.status === 200 &&
      props.historyData.data !== state.historyData
    ) {
      document.body.style.overflow = "hidden";
      return {
        historyData: props.historyData.data,
        showLoader: false,
        showPaymentHistory: true,
      };
    } else if (
      props.historyData !== undefined &&
      props.historyData.status !== 200 &&
      props.historyData.data !== state.historyData
    ) {
      document.body.style.overflow = "";
      return {
        showLoader: false,
        historyData: props.historyData.data,
      };
    }

    if (
      props.voidData !== undefined &&
      props.voidData.status === 200 &&
      props.voidData.data !== state.voidData
    ) {
      return {
        voidData: props.voidData.data,
        showLoader: false,
        saveMessage: props.voidData.message,
      };
    } else if (
      props.voidData !== undefined &&
      props.voidData.status !== 200 &&
      props.voidData.data !== state.voidData
    ) {
      return {
        showLoader: false,
        voidData: props.historyData ? props.historyData.data : null,
        saveMessage: props.voidData.message,
      };
    }

    if (
      props.sendAndDownloadInvoiceData !== undefined &&
      props.sendAndDownloadInvoiceData.status === 200 &&
      props.sendAndDownloadInvoiceData.data.timestamp !==
        state.sendAndDownloadInvoiceData
    ) {
      if (state.invoiceActionType === 0) {
        let returnState = {};
        returnState.sendAndDownloadInvoiceData =
          props.sendAndDownloadInvoiceData.data.timestamp;
        returnState.showLoader = false;
        returnState.invoiceActionType = "";
        if (props.sendAndDownloadInvoiceData.data) {
          window.open(props.sendAndDownloadInvoiceData.data.file);
        }
        return returnState;
      } else {
        return {
          sendAndDownloadInvoiceData:
            props.sendAndDownloadInvoiceData.data.timestamp,
          showLoader: false,
          invoiceActionType: "",
        };
      }
    } else if (
      props.sendAndDownloadInvoiceData !== undefined &&
      props.sendAndDownloadInvoiceData.status !== 200 &&
      props.sendAndDownloadInvoiceData.data.timestamp !==
        state.sendAndDownloadInvoiceData
    ) {
      return {
        showLoader: false,
        sendAndDownloadInvoiceData:
          props.sendAndDownloadInvoiceData.data.timestamp,
        invoiceActionType: "",
      };
    }

    if (
      props.sendAndDownloadReceiptData !== undefined &&
      props.sendAndDownloadReceiptData.status === 200 &&
      props.sendAndDownloadReceiptData.data !== state.sendAndDownloadReceiptData
    ) {
      if (state.receiptActionType === 0) {
        let returnState = {};
        returnState.sendAndDownloadReceiptData =
          props.sendAndDownloadReceiptData.data;
        returnState.showLoader = false;
        returnState.receiptActionType = "";
        if (props.sendAndDownloadReceiptData.data) {
          window.location.href = props.sendAndDownloadReceiptData.data;
        }
        return returnState;
      } else {
        return {
          sendAndDownloadReceiptData: props.sendAndDownloadReceiptData.data,
          showLoader: false,
          receiptActionType: "",
        };
      }
    } else if (
      props.sendAndDownloadReceiptData !== undefined &&
      props.sendAndDownloadReceiptData.status !== 200 &&
      props.sendAndDownloadReceiptData.data !== state.sendAndDownloadReceiptData
    ) {
      return {
        showLoader: false,
        sendAndDownloadReceiptData: props.sendAndDownloadReceiptData.data,
        receiptActionType: "",
      };
    }

    if (
      props.getRefundData !== undefined &&
      props.getRefundData.status === 200 &&
      props.getRefundData.data !== state.getRefundData
    ) {
      document.body.style.overflow = "hidden";
      const includesFullRefund =
        props.getRefundData.data &&
        props.getRefundData.data.available_options &&
        props.getRefundData.data.available_options.includes("full-refund");
      const includesCherryPayment =
        props.getRefundData.data &&
        props.getRefundData.data.refund_options &&
        props.getRefundData.data.refund_options.some(
          ({ payment_mode }) => payment_mode === "cherry",
        );

      return {
        getRefundData: props.getRefundData.data,
        showLoader: false,
        showRefund: true,
        refund_type:
          includesFullRefund && !includesCherryPayment ? "full" : "partial",
        refund_reason: "",
        isFullRefundPossibble: includesFullRefund && !includesCherryPayment,
        text_reason_for_refund: "",
        refundReasonClass: "row no-display",
        selectedPayments: [],
        cash_amount: "",
        cc_amount: "",
        wallet_amount: "",
        reasonAreaClass: "setting-textarea-box",
        cashAmountClass: "setting-input-box",
        ccAmountClass: "setting-input-box",
        walletAmountClass: "setting-input-box",
        checkAmountClass: "setting-input-box",
        selectAReasonClass: "setting-select-box",
        selectPaymentClass: "",
        care_credit_amount: "",
        greensky_amount: "",
        careCreditAmountClass: "setting-input-box",
        greenskyAmountClass: "setting-input-box",
      };
    } else if (
      props.getRefundData !== undefined &&
      props.getRefundData.status !== 200 &&
      props.getRefundData.data !== state.getRefundData
    ) {
      document.body.style.overflow = "";
      return {
        showLoader: false,
        getRefundData: props.getRefundData.data,
      };
    }

    if (
      props.payRefundData !== undefined &&
      props.payRefundData.status === 200 &&
      props.payRefundData.data !== state.payRefundData
    ) {
      document.body.style.overflow = "";
      return {
        payRefundData: props.payRefundData.data,
        showLoader: false,
        payRefundStatus: props.payRefundData.data,
      };
    } else if (
      props.payRefundData !== undefined &&
      props.payRefundData.status !== 200 &&
      props.payRefundData.data !== state.payRefundData
    ) {
      document.body.style.overflow = "";
      return {
        payRefundData: props.payRefundData.data,
        showLoader: false,
        payRefundStatus: "",
      };
    }

    if (
      props.updateInvoiceUserData !== undefined &&
      props.updateInvoiceUserData.status === 200 &&
      props.updateInvoiceUserData.data !== state.updateInvoiceUserData
    ) {
      return {
        updateInvoiceUserData: props.updateInvoiceUserData.data,
        showLoader: false,
        updateInvoiceUserStatus: props.updateInvoiceUserData.data,
      };
    } else if (
      props.updateInvoiceUserData !== undefined &&
      props.updateInvoiceUserData.status !== 200 &&
      props.updateInvoiceUserData.data !== state.updateInvoiceUserData
    ) {
      return {
        updateInvoiceUserData: props.updateInvoiceUserData.data,
        showLoader: false,
        updateInvoiceUserStatus: "",
      };
    }

    if (
      props.saveAndSendData !== undefined &&
      props.saveAndSendData.status === 200 &&
      props.saveAndSendData.data !== state.saveAndSendData
    ) {
      return {
        saveAndSendData: props.saveAndSendData.data,
        showLoader: false,
        saveAndSendDataStatus: props.saveAndSendData.data,
        showNoEmailError: false,
      };
    } else if (
      props.saveAndSendData !== undefined &&
      props.saveAndSendData.status !== 200 &&
      props.saveAndSendData.data !== state.saveAndSendData
    ) {
      return {
        saveAndSendData: props.saveAndSendData.data,
        showLoader: false,
        saveAndSendDataStatus: "",
        showNoEmailError: false,
      };
    }

    if (
      props.memberShipRefundTimestamp != undefined &&
      props.memberShipRefundTimestamp != state.memberShipRefundTimestamp
    ) {
      document.body.style.overflow = "";
      return {
        showLoader: false,
        showIssueConfirmationModal: false,
        memberShipRefundTimestamp: props.memberShipRefundTimestamp,
        payRefundData: props.memberShipRefundTimestamp,
        payRefundStatus: props.memberShipRefundTimestamp,
      };
    }

    if (
      props.voidAndRefundData !== undefined &&
      props.voidAndRefundData.status === 200 &&
      props.voidAndRefundData.data !== state.voidAndRefundData
    ) {
      document.body.style.overflow = "";
      return {
        voidAndRefundData: props.voidAndRefundData.data,
        showLoader: false,
        voidAndRefundStatus: props.voidAndRefundData.data,
        showRefundAndVoidOptions: false,
      };
    } else if (
      props.voidAndRefundData !== undefined &&
      props.voidAndRefundData.status !== 200 &&
      props.voidAndRefundData.data !== state.voidAndRefundData
    ) {
      document.body.style.overflow = "";
      return {
        voidAndRefundData: props.voidAndRefundData.data,
        showLoader: false,
        voidAndRefundStatus: "",
      };
    }
    return null;
  }

  printInvoice = () => {
    let htmlData = document.getElementById("print_area").innerHTML;
    let winPrint = window.open(
      "",
      "",
      "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0",
    );
    let inoiceTitle = "";
    let totalToBeRemoved = document.getElementsByClassName("proName").length;

    if (
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.invoice_number
    ) {
      inoiceTitle = " - " + this.state.invoiceData.invoice_data.invoice_number;
    }

    winPrint.document.write(
      `<html><head><title>Invoice ${inoiceTitle}</title>`,
    );
    winPrint.document.write(htmlData);

    winPrint.document.getElementById("proNameHead").remove();

    for (let i = 0; i < totalToBeRemoved; i++) {
      let idName = `proName_` + i;
      winPrint.document.getElementById(idName).remove();
    }

    winPrint.document.close();

    winPrint.focus();
    winPrint.print();
    setTimeout(function () {
      winPrint.close();
    }, 1000);
  };

  showPaymentHistory = () => {
    this.setState({
      showLoader: true,
    });

    this.props.getPaymentHistory(this.state.invoiceID).catch((res) => {
      uiNotification.error(this.state.globalLang[res.message]);
    });
  };

  hidePaymentHistory = () => {
    document.body.style.overflow = "";
    this.setState({ showPaymentHistory: false });
  };

  componentDidUpdate = (_, prevState) => {
    let returnTo = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      if (
        this.props.match.params.type &&
        this.props.match.params.type === "profile"
      ) {
        returnTo = this.state.backURLType
          ? "/" +
            this.state.backURLType +
            "/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.clientID
          : "/clients";
      } else {
        returnTo = this.state.backURLType
          ? "/" +
            this.state.backURLType +
            "/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.clientID +
            "/profile/invoices"
          : "/clients";
      }
    } else {
      if (
        this.props.match.params.type &&
        this.props.match.params.type === "invoices"
      ) {
        returnTo = this.state.backURLType
          ? "/" + this.state.backURLType + "/" + this.props.match.params.type
          : "/sales";
      } else {
        returnTo = this.state.backURLType ? "/sales/invoices" : "/sales";
      }
    }

    if (
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      parseInt(this.state.invoiceID) !== this.state.invoiceData.invoice_data.id
    ) {
      this.setState({
        showLoader: true,
        invoiceID: this.state.invoiceData.invoice_data.id,
      });
      let curObj = this;
      setTimeout(function () {
        curObj.props.history.push(
          generatePath(curObj.props.match.path, {
            ...curObj.props.match.params,
            invoiceID: curObj.state.invoiceData.invoice_data.id,
          }),
        );
        curObj.fetchInvoiceDetails(
          curObj.state.invoiceID,
          curObj.state.clientID,
        );
      }, 200);
      return;
    }

    if (
      this.state.voidData !== null &&
      this.state.voidData !== "" &&
      this.state.voidData !== prevState.voidData &&
      this.state.saveMessage !== null &&
      this.state.saveMessage !== ""
    ) {
      let curObj = "";

      uiNotification.success(this.state.globalLang[this.state.saveMessage]);
      curObj = this;
      setTimeout(function () {
        curObj.props.history.push(returnTo);
      }, 1000);
    }

    if (
      this.state.payRefundData !== null &&
      this.state.payRefundData !== "" &&
      this.state.payRefundData !== prevState.payRefundData &&
      this.state.payRefundStatus !== null &&
      this.state.payRefundStatus !== ""
    ) {
      this.setState({ showLoader: true });
      this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
    }

    if (
      this.state.updateInvoiceUserData !== null &&
      this.state.updateInvoiceUserData !== "" &&
      this.state.updateInvoiceUserData !== prevState.updateInvoiceUserData &&
      this.state.updateInvoiceUserStatus !== null &&
      this.state.updateInvoiceUserStatus !== ""
    ) {
      this.setState({ showLoader: true });
      this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
    }

    if (
      this.state.saveAndSendData !== null &&
      this.state.saveAndSendData !== "" &&
      this.state.saveAndSendData !== prevState.saveAndSendData &&
      this.state.saveAndSendDataStatus !== null &&
      this.state.saveAndSendDataStatus !== ""
    ) {
      this.setState({ showLoader: true });
      this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
    }

    if (
      this.state.voidAndRefundData !== null &&
      this.state.voidAndRefundData !== "" &&
      this.state.voidAndRefundData !== prevState.voidAndRefundData &&
      this.state.voidAndRefundStatus !== null &&
      this.state.voidAndRefundStatus !== ""
    ) {
      this.setState({ showLoader: true });
      this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
    }

    if (
      !prevState.invoiceData.patient_id &&
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.pos_invoice_items &&
      this.state.invoiceData.invoice_data.pos_invoice_items.length > 0
    ) {
      const newState = cloneDeep(this.state);
      const new_pos_invoice_items = cloneDeep(
        this.state.invoiceData.invoice_data.pos_invoice_items,
      );
      let packageItems = {};

      for (let i = new_pos_invoice_items.length - 1; i >= 0; i -= 1) {
        if (
          new_pos_invoice_items[i].product_type === "package" &&
          new_pos_invoice_items[i].product_id
        ) {
          if (
            packageItems.hasOwnProperty(new_pos_invoice_items[i].product_id)
          ) {
            packageItems[
              new_pos_invoice_items[i].product_id
            ].total_product_price +=
              new_pos_invoice_items[i].total_product_price;
            packageItems[new_pos_invoice_items[i].product_id].product_units +=
              new_pos_invoice_items[i].product_units;

            new_pos_invoice_items.splice(i, 1);
          } else {
            packageItems[new_pos_invoice_items[i].product_id] = {
              total_product_price: new_pos_invoice_items[i].total_product_price,
              product_units: new_pos_invoice_items[i].product_units,
            };
          }
        }
      }

      Object.entries(packageItems).forEach(([key, value]) => {
        const idx = new_pos_invoice_items.findIndex(
          ({ product_id }) => product_id == key,
        );

        new_pos_invoice_items[idx].total_product_price =
          value.total_product_price;
        new_pos_invoice_items[idx].product_units = value.product_units;
      });

      newState.invoiceData.invoice_data.pos_invoice_items =
        new_pos_invoice_items;

      this.setState(newState);
    }
  };

  showRefundModal = () => {
    this.setState({
      showLoader: true,
    });

    this.props.getRefundDetails(this.state.invoiceID).catch((res) => {
      uiNotification.error(this.state.globalLang[res.message]);
    });
  };

  hideRefundModal = () => {
    document.body.style.overflow = "";
    this.setState({
      showRefund: false,
    });
  };

  handleRefundSubmit = (e) => {
    e.preventDefault();

    let isValid = true;

    if (this.state.refund_type === "partial") {
      if (
        this.state.selectedPayments &&
        this.state.selectedPayments.length === 0
      ) {
        this.setState({ selectPaymentClass: "setting-input-box-invalid" });
        isValid = false;
      } else {
        if (!this.state.selectedPayments) {
          this.setState({ selectPaymentClass: "setting-input-box-invalid" });
          isValid = false;
        } else {
          this.setState({ selectPaymentClass: "" });
        }
      }

      if (this.state.refund_reason === "") {
        this.setState({
          selectAReasonClass: "setting-select-box setting-input-box-invalid",
        });
        isValid = false;
      } else {
        this.setState({ selectAReasonClass: "setting-select-box" });
      }

      if (this.state.refund_reason === "custom") {
        if (this.state.text_reason_for_refund === "") {
          this.setState({
            reasonAreaClass: "setting-textarea-box setting-input-box-invalid",
          });
          isValid = false;
        } else {
          this.setState({ reasonAreaClass: "setting-textarea-box" });
        }
      } else {
        this.setState({
          reasonAreaClass: "setting-textarea-box",
          text_reason_for_refund: "",
        });
      }

      if (this.state.selectedPayments && this.state.selectedPayments.obj) {
        let modeType =
          this.state.selectedPayments.obj.payment_mode.toLowerCase() ===
          "gift card"
            ? "gift_card"
            : this.state.selectedPayments.obj.payment_mode.toLowerCase();
        let maxRefundableAmnt = numberFormat(
          this.state.selectedPayments.obj.max_refund,
        ).replace(/,/g, "");

        const paymentTypeClasses = {
          cash: "cashAmountClass",
          check: "checkAmountClass",
          cc: "ccAmountClass",
          klarna: "ccAmountClass",
          link: "ccAmountClass",
          wallet: "walletAmountClass",
          care_credit: "careCreditAmountClass",
          greensky: "greenskyAmountClass",
        };

        const amount = Number(this.state[`${modeType}_amount`]);
        const isInvalidAmount =
          !isNumber(amount) ||
          amount > Number(maxRefundableAmnt) ||
          amount <= 0;
        const updatedClass = paymentTypeClasses[modeType];

        this.setState({
          [updatedClass]: `setting-input-box ${
            isInvalidAmount ? "setting-input-box-invalid" : ""
          }`,
        });

        if (isInvalidAmount) {
          isValid = false;
        }
      }
    } else {
      if (this.state.refund_reason === "") {
        this.setState({
          selectAReasonClass: "setting-select-box setting-input-box-invalid",
        });
        isValid = false;
      } else {
        this.setState({ selectAReasonClass: "setting-select-box" });
      }

      if (this.state.refund_reason === "custom") {
        if (this.state.text_reason_for_refund === "") {
          this.setState({
            reasonAreaClass: "setting-textarea-box setting-input-box-invalid",
          });
          isValid = false;
        } else {
          this.setState({ reasonAreaClass: "setting-textarea-box" });
        }
      } else {
        this.setState({
          reasonAreaClass: "setting-textarea-box",
          text_reason_for_refund: "",
        });
      }
    }

    if (isValid) {
      if (this.state.refund_type === "partial") {
        const paymentModeAmount = {
          cash: "cash_amount",
          cc: "cc_amount",
          wallet: "wallet_amount",
          check: "check_amount",
          care_credit: "care_credit_amount",
          greensky: "greensky_amount",
          "Gift Card": "gift_card_amount",
          Link: "link_amount",
          Klarna: "klarna_amount",
        };

        const paymentMode = this.state.selectedPayments.obj.payment_mode;
        const property = paymentModeAmount[paymentMode];

        let formData = {
          invoice_id: this.state.invoiceID,
          transaction_id:
            this.state.invoiceData &&
            this.state.invoiceData.invoice_data &&
            this.state.invoiceData.invoice_data.pos_transaction &&
            this.state.invoiceData.invoice_data.pos_transaction.id &&
            this.state.invoiceData.invoice_data.pos_transaction.id,
          refund_type: this.state.refund_type,
          refund_reason: this.state.refund_reason,
          text_reason_for_refund: this.state.text_reason_for_refund,
          partial_refund_ids: [
            {
              payment_id:
                this.state.selectedPayments && this.state.selectedPayments.obj
                  ? this.state.selectedPayments.obj.pos_transaction_payment_id
                  : 0,
              payment_via: paymentMode,
              payment_amount: this.state[property],
            },
          ],
        };

        if (
          this.state.invoiceData?.invoice_data?.pos_invoice_items[0]
            ?.product_type !== "monthly_membership"
        ) {
          formData.payment_refund_type = this.state.payment_refund_type;
        }

        if (paymentMode && paymentMode in paymentModeAmount) {
          formData[property] = this.state[property];
        }

        if (this.state.refund_reason === "custom") {
          formData.text_reason_for_refund = this.state.text_reason_for_refund;
        }

        this.setState({ showLoader: true });
        document.body.style.overflow = "";
        this.props.payRefund(formData);
      } else {
        let formData = {
          invoice_id: this.state.invoiceID,
          transaction_id:
            this.state.invoiceData &&
            this.state.invoiceData.invoice_data &&
            this.state.invoiceData.invoice_data.pos_transaction &&
            this.state.invoiceData.invoice_data.pos_transaction.id &&
            this.state.invoiceData.invoice_data.pos_transaction.id,
          refund_type: this.state.refund_type,
          refund_reason: this.state.refund_reason,
          text_reason_for_refund: this.state.text_reason_for_refund,
          payment_refund_type: this.state.payment_refund_type,
        };

        if (
          this.state.invoiceData &&
          this.state.invoiceData.invoice_data &&
          this.state.invoiceData.invoice_data.pos_invoice_items[0] &&
          this.state.invoiceData.invoice_data.pos_invoice_items[0][
            "product_type"
          ] &&
          this.state.invoiceData.invoice_data.pos_invoice_items[0][
            "product_type"
          ] === "monthly_membership"
        ) {
          delete formData.payment_refund_type;
        }

        if (this.state.refund_reason !== "custom") {
          delete formData.text_reason_for_refund;
        }

        this.setState({ showLoader: true });
        document.body.style.overflow = "";
        this.props.payRefund(formData);
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });

    if (event.target.name === "refund_type") {
      this.setState({ selectedPayments: [] });
    }

    if (event.target.name === "refund_reason") {
      if (value !== "") {
        if (value === "custom") {
          this.setState({ refundReasonClass: "row row m-t-10" });
        } else {
          this.setState({ refundReasonClass: "row no-display" });
        }
      } else {
        this.setState({ refundReasonClass: "row no-display" });
      }
    }

    if (event.target.name === "void_type") {
      if (value !== "") {
        if (value === "void_without_refund") {
          this.setState({
            selectAReasonClass: "setting-select-box",
            reasonAreaClass: "setting-textarea-box",
            refund_reason: "",
            text_reason_for_refund: "",
            refundReasonClass: "row no-display",
          });
        }
      }
    }
  };

  printOrDownloadInvoice = (type) => {
    let error = false;

    if (type && type === 1) {
      let patEmail = "";

      patEmail =
        this.state.invoiceData?.invoice_data?.patient?.email ||
        this.state.invoiceData?.invoice_data?.from_email ||
        "";

      if (!patEmail) {
        error = true;
      }
    }

    if (!error) {
      this.setState({
        showLoader: true,
        invoiceActionType: type,
      });

      this.props
        .sendAndDownloadInvoice(this.state.invoiceID, type)
        .then((res) => {
          if (type === 1) {
            uiNotification.success(this.state.globalLang[res.message]);
          }
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] ||
              tCommon(`apiError.${res.message}`, "error.fallback"),
          );
        });
    } else {
      this.setState({
        showNoEmailError: true,
        emailInputClass: "setting-input-box",
        pat_email_id: "",
      });
    }
  };

  printOrDownloadReceipt = (type) => {
    this.setState({
      showLoader: true,
      receiptActionType: type,
    });

    this.props.sendAndDownloadRefundReceipt(this.state.invoiceID, type);
  };

  handlePaymentChange = (selectedOption) => {
    const returnState = {};
    const paymentTypeClasses = {
      cash: "cashAmountClass",
      check: "checkAmountClass",
      cc: "ccAmountClass",
      klarna: "ccAmountClass",
      link: "ccAmountClass",
      wallet: "walletAmountClass",
      care_credit: "careCreditAmountClass",
      greensky: "greenskyAmountClass",
    };
    const updatedClass =
      paymentTypeClasses[selectedOption.obj.payment_mode.toLowerCase()];
    if (selectedOption?.obj?.payment_mode?.toLowerCase() === "gift card") {
      returnState.payment_refund_type = "cash_to_wallet";
      returnState.gift_card_amount = selectedOption?.obj?.total_amount;
    }

    returnState.selectedPayments = selectedOption;
    returnState[`${selectedOption.obj.payment_mode.toLowerCase()}_amount`] = "";
    returnState[updatedClass] = "setting-input-box";
    this.setState(returnState);
  };

  handleProviderChange = (selectedOption, invoiveItemID) => {
    let x = this.state.selectedProvider;
    x[invoiveItemID] = selectedOption;
    this.setState({ selectedProvider: x, showLoader: true });

    let formData = {
      user_id: selectedOption.value,
      pos_invoice_item_id: invoiveItemID,
    };

    this.props
      .changeUserID(formData)
      .then((res) => {
        uiNotification.success(this.state.globalLang[res.message]);
      })
      .catch((res) => {
        uiNotification.error(this.state.globalLang[res.message]);
      });
  };

  componentWillUnmount = () => {
    this.props.exportEmptyData({});
  };

  closeEmailPopup = () => {
    this.setState({
      showNoEmailError: false,
      emailInputClass: "setting-input-box",
      pat_email_id: "",
    });
  };

  handleEmailSubmit = (e) => {
    e.preventDefault();

    let error = false;

    if (!this.state.pat_email_id) {
      error = true;
      this.setState({
        emailInputClass: "setting-input-box setting-input-box-invalid",
      });
    }

    if (
      this.state.pat_email_id &&
      !validator.isEmail(this.state.pat_email_id)
    ) {
      error = true;
      this.setState({
        emailInputClass: "setting-input-box setting-input-box-invalid",
      });
    }

    if (!error) {
      this.setState({
        emailInputClass: "setting-input-box",
        showLoader: true,
      });

      let formData = {
        patient_id: this.state.clientID,
        email: this.state.pat_email_id,
        invoice_id: this.state.invoiceID,
      };

      this.props
        .saveAndSendEmail(formData)
        .then(() => {
          uiNotification.success(
            <div>
              {`Email saved successfully`}
              <br />
              Invoice is sent to customer email
            </div>,
          );
        })
        .catch((res) => {
          uiNotification.error(
            this.state.globalLang[res.message] ||
              tCommon(`apiError.${res.message}`, "error.fallback"),
          );
        });
    }
  };

  refundIssue = () => {
    this.setState({ showLoader: true, showIssueConfirmationModal: false });
    this.props.refundIssue(this.state.invoiceID).catch((res) => {
      uiNotification.error(this.state.globalLang[res.message]);
    });
  };

  showRefundAndVoidOptions = () => {
    this.setState({
      showRefundAndVoidOptions: true,
    });
  };

  hideRefundAndVoidOptions = () => {
    this.setState({
      showRefundAndVoidOptions: false,
      void_type: "refund_full_and_void",
      selectAReasonClass: "setting-select-box",
      reasonAreaClass: "setting-textarea-box",
      refund_reason: "",
      text_reason_for_refund: "",
      refundReasonClass: "row no-display",
    });
  };

  handleVoidAndRefundSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    const isInvoiceVoidable = Boolean(
      this.state.invoiceData?.invoice_data?.is_voidable,
    );

    if (!isInvoiceVoidable) {
      this.setState({ showRefundAndVoidOptions: false });
    }

    if (
      this.state.void_type &&
      this.state.void_type === "refund_full_and_void"
    ) {
      if (!this.state.refund_reason || this.state.refund_reason === "") {
        this.setState({
          selectAReasonClass: "setting-select-box setting-input-box-invalid",
        });
        isValid = false;
      } else {
        this.setState({ selectAReasonClass: "setting-select-box" });
      }

      if (this.state.refund_reason === "custom") {
        if (
          !this.state.text_reason_for_refund ||
          this.state.text_reason_for_refund === ""
        ) {
          this.setState({
            reasonAreaClass: "setting-textarea-box setting-input-box-invalid",
          });
          isValid = false;
        } else {
          this.setState({ reasonAreaClass: "setting-textarea-box" });
        }
      } else {
        this.setState({
          reasonAreaClass: "setting-textarea-box",
          text_reason_for_refund: "",
        });
      }
    }

    if (isValid) {
      let formData = {
        invoice_id: this.state.invoiceID,
        transaction_id:
          this.state.invoiceData &&
          this.state.invoiceData.invoice_data &&
          this.state.invoiceData.invoice_data.pos_transaction &&
          this.state.invoiceData.invoice_data.pos_transaction.id &&
          this.state.invoiceData.invoice_data.pos_transaction.id,
        void_type: this.state.void_type,
        refund_reason: this.state.refund_reason,
        text_reason_for_refund: this.state.text_reason_for_refund,
        payment_refund_type: "default",
        refund_type: "full",
      };

      if (
        this.state.invoiceData &&
        this.state.invoiceData.invoice_data &&
        this.state.invoiceData.invoice_data.pos_invoice_items[0] &&
        this.state.invoiceData.invoice_data.pos_invoice_items[0][
          "product_type"
        ] &&
        this.state.invoiceData.invoice_data.pos_invoice_items[0][
          "product_type"
        ] === "monthly_membership"
      ) {
        delete formData.payment_refund_type;
      }

      if (this.state.refund_reason !== "custom") {
        delete formData.text_reason_for_refund;
      }

      if (this.state.void_type !== "refund_full_and_void") {
        delete formData.refund_reason;
        delete formData.text_reason_for_refund;
      }

      if (this.state.void_type === "void_and_send_to_wallet") {
        formData.refund_reason = "invoice voided and amount sent to wallet";
        formData.payment_refund_type = "cash_to_wallet";
      }

      this.setState({ showLoader: true });
      document.body.style.overflow = "";
      if (isInvoiceVoidable) {
        this.props.voidAndRefund(formData);
      }
    }
  };

  canRefund = () => {
    if (
      !(
        this.state &&
        this.state.invoiceData &&
        this.state.invoiceData.invoice_data &&
        this.state.invoiceData.invoice_data &&
        this.state.invoiceData.invoice_data.pos_transaction &&
        this.state.invoiceData.invoice_data.pos_transaction
          .pos_transactions_payments.length
      )
    ) {
      return;
    }

    const payments =
      this.state.invoiceData.invoice_data.pos_transaction
        .pos_transactions_payments;
    return payments.some(({ payment_mode }) => payment_mode !== "cherry");
  };

  getRefundAmountPlaceholder() {
    const maxRefund = unwrapOr(
      () => this.state.selectedPayments.obj.max_refund,
      null,
    );
    if (typeof maxRefund === "number" && maxRefund > 0) {
      return `Ex: ${(maxRefund / 2).toFixed(2)}`;
    }
    return "";
  }

  getDefaultRefundModeTitle = (paymentMode, chequeNo, ccType) => {
    let nextPaymentMode = this.state.globalLang.inv_cash;
    if (paymentMode === PAYMENT_MODE_KEYS.cc) {
      if (["klarna", "link"].includes(ccType?.toLowerCase())) {
        nextPaymentMode = capitalizeFirstLetter(ccType);
      } else {
        nextPaymentMode = this.state.globalLang.inv_credit_card;
      }
    } else if (paymentMode === PAYMENT_MODE_KEYS.wallet) {
      nextPaymentMode = this.state.globalLang.inv_wallet;
    } else if (paymentMode === PAYMENT_MODE_KEYS.check) {
      nextPaymentMode = this.state.salesLang.frontdesk_cheque;
      if (chequeNo !== "" && chequeNo !== null) {
        nextPaymentMode += " #" + chequeNo;
      }
    } else if (paymentMode === PAYMENT_MODE_KEYS.careCredit) {
      nextPaymentMode = "Care Credit";
    } else if (paymentMode === PAYMENT_MODE_KEYS.greenSky) {
      nextPaymentMode = "Greensky";
    } else if (paymentMode === PAYMENT_MODE_KEYS.giftCard) {
      nextPaymentMode = "Wallet";
    }

    return nextPaymentMode;
  };

  collectRefunds = () => {
    const payments = unwrapOr(
      () =>
        this.state.invoiceData.invoice_data.pos_transaction
          .pos_transactions_payments,
      [],
    );

    const refundedPayments = payments.filter(
      (payment) =>
        payment.payment_status === "refunded" ||
        payment.payment_status === "partial_refunded",
    );

    const composeRefundPaymentMode = (
      refundTo,
      parentPaymentMode,
      parentChequeNo,
      ccType = null,
    ) => {
      if (refundTo === REFUND_TO_KEYS.wallet) {
        return "Wallet";
      } else if (refundTo === REFUND_TO_KEYS.default) {
        return this.getDefaultRefundModeTitle(
          parentPaymentMode,
          parentChequeNo,
          ccType,
        );
      }
    };

    const composeRefundReason = (refundReason = "") => {
      return refundReason.replace("_", " ");
    };

    const composeRefundAmount = (amount) => {
      return numberFormat(amount, "currency", 2);
    };

    const composeRefundDate = (date) => {
      return showFormattedDate(date || "", false, "MMMM DD, Y");
    };

    return refundedPayments.reduce((refunds, payment) => {
      const paymentRefunds = payment.pos_transactions_payments_refunds || [];
      const nextPaymentRefunds = paymentRefunds.map((paymentRefund) => ({
        paymentMode: composeRefundPaymentMode(
          paymentRefund.refund_to,
          payment.payment_mode,
          payment.cheque_no,
          payment.cc_type,
        ),
        reason: composeRefundReason(paymentRefund.refund_reason),
        amount: composeRefundAmount(paymentRefund.amount),
        date: composeRefundDate(
          paymentRefund.created_at || payment.refund_datetime,
        ),
        ccType: capitalizeFirstLetter(payment.cc_type),
      }));
      return [...refunds, ...nextPaymentRefunds];
    }, []);
  };

  isInvoiceMergeRefund = () =>
    (this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.is_merger) ||
    false;

  invoiceMergeRefundOptions = () =>
    this.state.invoiceData.invoice_merge_refund_options || [];

  refundMergedInvoiceByType = (refundOption) => {
    if (refundOption.type === "membership") {
      return this.confirmRefundMembershipInvoice(
        refundOption.merged_invoice_id,
      );
    }

    return this.showRefundModal();
  };

  confirmRefundMembershipInvoice = (mergedInvoiceId) => {
    this.setState({
      showIssueConfirmationModal: true,
      refundInvoiceId: mergedInvoiceId,
    });
  };

  refundMembershipInvoice = () => {
    this.setState({ showIssueConfirmationModal: false, showLoader: true });
    this.props.refundIssue(this.state.refundInvoiceId).catch((res) => {
      uiNotification.error(this.state.globalLang[res.message]);
    });
  };

  redirectToPayment = () => {
    this.props.history.push(
      `/sales/checkout-invoice/${this.state.invoiceData.invoice_data.id}`,
    );
  };

  get isSourceRefundAvailable() {
    return (
      this.state.refund_type !== "partial" ||
      this.state.selectedPayments?.obj?.payment_mode?.toLowerCase() !==
        "gift card"
    );
  }

  get haveGiftCardPayment() {
    const giftCardPayments = this.state.getRefundData?.refund_options?.filter(
      ({ payment_mode }) => payment_mode.toLowerCase() === "gift card",
    );
    return giftCardPayments?.length > 0;
  }

  get isGiftCardNoticeVisible() {
    return (
      this.haveGiftCardPayment &&
      this.state.refund_type === "full" &&
      this.state.payment_refund_type === "default"
    );
  }

  handleTipsPerItemChange = (value, invoiceItemId) => {
    const tips = this.state.tipsPerItem;
    tips[invoiceItemId] = value || 0;
    this.setState({ tipsPerItem: tips });
  };

  handleCancelTipsPerItem = () => {
    const tipsPerItem =
      this.state.invoiceData.invoice_data.pos_invoice_items.reduce(
        (acc, item) => {
          return {
            ...acc,
            [item.id]: item.tip_amount_per_item.toFixed(2),
          };
        },
        {},
      );

    this.setState({ tipEditMode: false, tipsPerItem });
  };

  handleSaveTipsPerItem = async () => {
    const tips = Object.entries(this.state.tipsPerItem).map(([id, amount]) => ({
      item_id: parseInt(id),
      tip_amount: parseFloat(amount),
    }));

    const formData = { tips: tips };
    try {
      this.setState({ showLoader: true, tipEditMode: false });
      const response = await editTipsPerItem(
        this.state.invoiceData.invoice_data.id,
        formData,
      );
      this.fetchInvoiceDetails(this.state.invoiceID, this.state.clientID);
      uiNotification.success(
        tSales(`invoicePreview.apiSuccess.${response.data.message}`),
      );
    } catch (e) {
      uiNotification.error(e.response.data.message);
      this.setState({ tipEditMode: true });
    }
    this.setState({ showLoader: false });
  };

  get isTipDistributable() {
    return (
      this.state.invoiceData?.invoice_data?.is_tip_distributable &&
      this.state.invoiceData?.invoice_data?.pos_invoice_items?.length > 1
    );
  }

  get ccPaymentModes() {
    return ["cc", "link", "klarna"];
  }

  get ccPaymentModesAmountString() {
    return `${this.state.selectedPayments?.obj?.payment_mode.toLowerCase()}_amount`;
  }

  isUserTipEnabled = (user) => {
    return Boolean(user.user_account_property?.is_tips_enabled);
  };

  get patientEmail() {
    return (
      this.state.invoiceData?.invoice_data?.patient?.email ||
      this.state.invoiceData?.invoice_data?.from_email
    );
  }

  get patientName() {
    if (
      this.state.invoiceData?.invoice_data?.patient?.full_name?.toLowerCase() ===
      "guest patient"
    ) {
      return this.state.invoiceData?.invoice_data?.from;
    }
    return null;
  }

  get hasRepeatMdRedemptions() {
    const hasRepeatMdRedemptions =
      this.state.invoiceData?.invoice_data?.has_rmd_redemptions;
    return hasRepeatMdRedemptions;
  }

  get repeatMdRedemptionsItems() {
    const repeatMdRedemptions =
      this.state.invoiceData?.invoice_data?.repeat_md_redeemed_items;
    return repeatMdRedemptions;
  }

  get totalTransactionsPayments() {
    const totalTransactionsPayments =
      this.state.invoiceData.invoice_data?.pos_transaction?.pos_transactions_payments?.reduce(
        (acc, payment) => {
          return acc + payment.total_amount;
        },
        0,
      );

    return totalTransactionsPayments;
  }

  get discountDebitsAndTransferCredit() {
    const subTotal = parseFloat(
      this.state.invoiceData?.invoice_data?.sub_total || 0,
    );
    const totalAmount = parseFloat(
      this.state.invoiceData?.invoice_data?.total_amount || 0,
    );
    const totalTax = parseFloat(
      this.state.invoiceData?.invoice_data?.total_tax || 0,
    );
    const tipAmount = parseFloat(
      this.state.invoiceData?.invoice_data?.tip_amount || 0,
    );

    return subTotal - (totalAmount - totalTax - tipAmount);
  }

  get isGuestPatient() {
    const patientName =
      this.state.invoiceData?.invoice_data?.patient?.full_name;

    return (
      patientName?.toLowerCase() === "guest patient" ||
      patientName?.toLowerCase() === "guest checkout"
    );
  }

  render() {
    let returnTo = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      if (
        this.props.match.params.type &&
        this.props.match.params.type === "profile"
      ) {
        returnTo = this.state.backURLType
          ? "/" +
            this.state.backURLType +
            "/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.clientID
          : "/clients";
      } else {
        returnTo = this.state.backURLType
          ? "/" +
            this.state.backURLType +
            "/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.clientID +
            "/profile/invoices"
          : "/clients";
      }
    } else {
      if (
        this.props.match.params.type &&
        this.props.match.params.type === "invoices"
      ) {
        returnTo = this.state.backURLType
          ? "/" + this.state.backURLType + "/" + this.props.match.params.type
          : "/sales";
      } else {
        returnTo = this.state.backURLType ? "/sales/invoices" : "/sales";
      }
    }

    let logoSrc =
      this.state.invoiceData && this.state.invoiceData.logo_img_src
        ? this.state.invoiceData.logo_img_src
        : defLogo;

    let inoiceTitle =
      this.state.invoiceData && this.state.invoiceData.invoice_data
        ? this.state.invoiceData.invoice_data.title
        : "";

    let address = "";
    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.address_line_1 &&
      this.state.invoiceData.invoice_data.patient.address_line_1 !== ""
        ? this.state.invoiceData.invoice_data.patient.address_line_1
        : "";

    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.address_line_2 &&
      this.state.invoiceData.invoice_data.patient.address_line_2 !== ""
        ? ", " + this.state.invoiceData.invoice_data.patient.address_line_2
        : "";

    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.city &&
      this.state.invoiceData.invoice_data.patient.city !== ""
        ? ", " + this.state.invoiceData.invoice_data.patient.city
        : "";

    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.state &&
      this.state.invoiceData.invoice_data.patient.state !== ""
        ? ", " + this.state.invoiceData.invoice_data.patient.state
        : "";

    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.country_name &&
      this.state.invoiceData.invoice_data.patient.country_name !== ""
        ? ", " + this.state.invoiceData.invoice_data.patient.country_name
        : "";

    address +=
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient &&
      this.state.invoiceData.invoice_data.patient.pincode &&
      this.state.invoiceData.invoice_data.patient.pincode !== ""
        ? ", " + this.state.invoiceData.invoice_data.patient.pincode
        : "";

    let isMonthlyMember =
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.patient
        ? this.state.invoiceData.invoice_data.patient.is_monthly_membership
        : 0;

    let refundOptions = "refunded";
    let refundedAmnt = 0;
    let totalPaidAmnt = 0;

    const isInvoiceVoidable =
      !!this.state.invoiceData?.invoice_data?.is_voidable;

    const voidableMessage =
      this.state.invoiceData?.invoice_data?.voidable_message || "";

    const isNotVoidableBecauseTimePassed =
      voidableMessage ===
      "invoice_void_not_allowed_because_48_hours_have_passed";

    let showVoidButton = isInvoiceVoidable || isNotVoidableBecauseTimePassed;

    let showPaymentHistory = !["draft", "pending"].includes(
      this.state.invoiceData?.invoice_data?.invoice_status,
    );
    this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.pos_transaction &&
      this.state.invoiceData.invoice_data.pos_transaction
        .pos_transactions_payments &&
      this.state.invoiceData.invoice_data.pos_transaction
        .pos_transactions_payments.length > 0 &&
      this.state.invoiceData.invoice_data.pos_transaction.pos_transactions_payments.map(
        (obj) => {
          refundedAmnt += obj.refund_amount;
          totalPaidAmnt += obj.total_amount;
        },
      );

    if (
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.pos_transaction &&
      this.state.invoiceData.invoice_data.pos_transaction
        .pos_transactions_payments &&
      this.state.invoiceData.invoice_data.pos_transaction
        .pos_transactions_payments.length > 0
    ) {
      if (refundedAmnt === totalPaidAmnt) {
        refundOptions = "refunded";
      } else {
        refundOptions = "paid";
      }
    }

    if (
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.title &&
      this.state.invoiceData.invoice_data.title === "Treatment Plan"
    ) {
      refundOptions = "refunded";
    }

    if (
      this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.invoice_status === "canceled"
    ) {
      refundOptions = "refunded";
    }

    let showRefund = true;

    let showHideRefundBtn = false;

    this.state.invoiceData &&
      this.state.invoiceData.invoice_data &&
      this.state.invoiceData.invoice_data.pos_invoice_items &&
      this.state.invoiceData.invoice_data.pos_invoice_items.length > 0 &&
      this.state.invoiceData.invoice_data.pos_invoice_items.map((obj) => {
        let productType = obj.product_type;

        if (productType === "monthly_membership") {
          showHideRefundBtn = true;
          return;
        }
      });

    var availPayments = [];

    this.state.getRefundData &&
      this.state.getRefundData.refund_options &&
      this.state.getRefundData.refund_options.length > 0 &&
      this.state.getRefundData.refund_options.map((obj) => {
        if (obj.payment_mode === "cherry") {
          return;
        }

        let labelStr =
          numberFormat(obj.max_refund, "currency") +
          ` paid via ` +
          (obj.payment_mode && obj.payment_mode === "care_credit"
            ? "care credit"
            : obj.payment_mode);

        availPayments.push({
          value: obj.pos_transaction_payment_id,
          label: labelStr,
          obj: obj,
        });
      });

    let showNameOnly =
      this.state.invoiceData && this.state.invoiceData.can_edit === 0
        ? "true"
        : "false";

    var defaultProviders = [];

    if (
      this.state.invoiceData &&
      this.state.invoiceData.all_employees &&
      this.state.invoiceData.all_employees.length > 0
    ) {
      this.state.invoiceData.all_employees.map((obj) => {
        defaultProviders.push({ value: obj.id, label: displayName(obj) });
      });
    }

    const refunds = this.collectRefunds();

    if (refunds.length > 0) {
      showRefund = false;
    }

    const items = unwrapOr(
      () => this.state.invoiceData.invoice_data.pos_invoice_items,
      [],
    );
    const giftCardItems = items.filter((p) => p.product_type === "egiftcard");
    const mergedInvoicesGiftCardPurchases = unwrapOr(
      () =>
        this.state.invoiceData.invoice_data.merged_invoices_egiftcard_purchases,
      [],
    );
    const giftCardPurchases =
      mergedInvoicesGiftCardPurchases.length === 0
        ? {
            [items[0]?.id]:
              this.state.invoiceData?.invoice_data?.egift_cards_purchase_first, // fallback for single invoice with single item
          }
        : mergedInvoicesGiftCardPurchases;

    const giftCardsAmountsByItemId = giftCardItems.reduce((result, item) => {
      const giftCardVoucher =
        giftCardPurchases[item.merged_item_id || item.id]?.egift_card_voucher;
      return {
        ...result,
        [item.id]: {
          amount: giftCardVoucher?.amount || 0,
          code: giftCardVoucher?.formatted_redemption_code || "",
        },
      };
    }, {});

    const isChangeClinicAllowed = checkIfPermissionAllowed(
      "invoice-provider-change",
    );

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div
            className={
              this.state.showNoEmailError === true ? "modalOverlay" : ""
            }
          >
            <form onSubmit={this.handleEmailSubmit}>
              <div
                className={
                  this.state.showNoEmailError === true
                    ? "small-popup-outer appointment-detail-main displayBlock"
                    : "small-popup-outer appointment-detail-main no-display"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.globalLang.inv_email_not_configure}
                  </div>
                  <a
                    onClick={() => this.closeEmailPopup()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                <div className="small-popup-content">
                  <div className="juvly-container no-padding-bottom">
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="setting-field-outer">
                          <div className="new-field-label">
                            {
                              this.state.globalLang
                                .inv_please_enter_email_address
                            }
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.emailInputClass}
                            type="text"
                            name="pat_email_id"
                            value={this.state.pat_email_id}
                            onChange={this.handleInputChange}
                            placeholder={this.state.globalLang.emailAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <input
                    type="submit"
                    className="new-blue-btn pull-right"
                    value={this.state.globalLang.inv_save_and_send}
                  />
                </div>
              </div>
            </form>
          </div>

          <div
            className={
              this.state.showLoader === false &&
              this.state.showRefundAndVoidOptions === true
                ? "modalOverlay"
                : ""
            }
          >
            <form onSubmit={this.handleVoidAndRefundSubmit}>
              <div
                className={
                  this.state.showLoader === false &&
                  this.state.showRefundAndVoidOptions === true
                    ? "small-popup-outer appointment-detail-main displayBlock xs-popup"
                    : "small-popup-outer appointment-detail-main no-display xs-popup"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.globalLang.inv_void}
                  </div>
                  <a
                    onClick={() => this.hideRefundAndVoidOptions()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                {isInvoiceVoidable ? (
                  <div className="small-popup-content">
                    <div className="juvly-container">
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="setting-field-outer">
                            <div className="new-field-label">{`Please select an option`}</div>
                            <div className="setting-input-outer">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="refund_full_and_void"
                                  className="basic-form-checkbox"
                                  name="void_type"
                                  type="radio"
                                  value="refund_full_and_void"
                                  checked={
                                    this.state.void_type ===
                                    "refund_full_and_void"
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />

                                <label
                                  className="basic-form-text"
                                  htmlFor="refund_full_and_void"
                                >{`Refund Full & Void`}</label>
                              </div>

                              <div className="basic-checkbox-outer">
                                <input
                                  id="void_without_refund"
                                  className="basic-form-checkbox"
                                  name="void_type"
                                  type="radio"
                                  value="void_without_refund"
                                  checked={
                                    this.state.void_type ===
                                    "void_without_refund"
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />

                                <label
                                  className="basic-form-text"
                                  htmlFor="void_without_refund"
                                >{`Void Without Refund`}</label>
                              </div>

                              {this.state.isTouchMD == 1 &&
                              this.state.plan_selected == "practice_booking"
                                ? ""
                                : !this.isGuestPatient && (
                                    <div className="basic-checkbox-outer">
                                      <input
                                        id="void_and_send_to_wallet"
                                        className="basic-form-checkbox"
                                        name="void_type"
                                        type="radio"
                                        value="void_and_send_to_wallet"
                                        checked={
                                          this.state.void_type ===
                                          "void_and_send_to_wallet"
                                            ? true
                                            : false
                                        }
                                        onChange={this.handleInputChange}
                                      />

                                      <label
                                        className="basic-form-text"
                                        htmlFor="void_and_send_to_wallet"
                                      >{`Void And Send To Wallet`}</label>
                                    </div>
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.void_type &&
                        this.state.void_type === "refund_full_and_void" && (
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="setting-field-outer">
                                <div className="new-field-label">
                                  {this.state.globalLang.inv_select_a_reason}{" "}
                                  <span className="required">*</span>
                                </div>
                                <div className="setting-input-outer">
                                  <select
                                    className={this.state.selectAReasonClass}
                                    name="refund_reason"
                                    onChange={this.handleInputChange}
                                    value={this.state.refund_reason}
                                  >
                                    <option value="">
                                      {this.state.globalLang.label_select}
                                    </option>
                                    <option value="returned_goods">
                                      {this.state.globalLang.inv_returned_goods}
                                    </option>
                                    <option value="accidental_charge">
                                      {
                                        this.state.globalLang
                                          .inv_accidental_charge
                                      }
                                    </option>
                                    <option value="canceled_order">
                                      {this.state.globalLang.inv_canceled_order}
                                    </option>
                                    <option value="custom">
                                      {this.state.globalLang.inv_custom}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {this.state.void_type &&
                        this.state.void_type === "refund_full_and_void" && (
                          <div className={this.state.refundReasonClass}>
                            <div className="col-xs-12">
                              <div className="new-field-label">
                                {this.state.globalLang.inv_reason_for_refund}{" "}
                                <span className="required">*</span>
                              </div>
                              <textarea
                                className={this.state.reasonAreaClass}
                                name="text_reason_for_refund"
                                onChange={this.handleInputChange}
                                value={this.state.text_reason_for_refund}
                              ></textarea>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ) : (
                  <AlertBox className={classes.notVoidableAlert}>
                    {tSales(
                      `invoicePreview.apiError.voidAndRefund.${voidableMessage}`,
                    )}
                  </AlertBox>
                )}
                <div className="footer-static">
                  <input
                    type="submit"
                    className={cx("new-blue-btn pull-right")}
                    value="Ok"
                  />
                </div>
              </div>
            </form>
          </div>

          <div
            className={
              this.state.showLoader === false && this.state.showRefund === true
                ? "modalOverlay"
                : ""
            }
          >
            <form onSubmit={this.handleRefundSubmit}>
              <div
                className={
                  this.state.showLoader === false &&
                  this.state.showRefund === true
                    ? "small-popup-outer appointment-detail-main displayBlock"
                    : "small-popup-outer appointment-detail-main no-display"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.globalLang.inv_issue_refund}
                  </div>
                  <a
                    onClick={() => this.hideRefundModal()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                <div className="small-popup-content m-h-300">
                  {this.state.getRefundData &&
                    this.state.getRefundData.refund_options &&
                    this.state.getRefundData.refund_options.length > 0 && (
                      <div className="juvly-container">
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">
                                {this.state.globalLang.inv_refund_type}
                              </div>
                              <div className="setting-input-outer">
                                <div className="basic-checkbox-outer">
                                  <input
                                    id="full"
                                    className="basic-form-checkbox"
                                    name="refund_type"
                                    type="radio"
                                    value="full"
                                    checked={
                                      this.state.refund_type === "full"
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleInputChange}
                                    disabled={!this.state.isFullRefundPossibble}
                                  />
                                  <label
                                    className="basic-form-text"
                                    htmlFor="full"
                                  >
                                    {this.state.globalLang.inv_full_refund}
                                  </label>
                                </div>
                                <div className="basic-checkbox-outer">
                                  <input
                                    id="partial"
                                    className="basic-form-checkbox"
                                    name="refund_type"
                                    type="radio"
                                    value="partial"
                                    checked={
                                      this.state.refund_type === "partial"
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                  <label
                                    className="basic-form-text"
                                    htmlFor="partial"
                                  >
                                    {this.state.globalLang.inv_partial_refund}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">
                                {this.state.globalLang.inv_select_payment}{" "}
                                <span className="required">*</span>
                              </div>

                              {this.state.getRefundData &&
                                this.state.refund_type === "full" && (
                                  <div className="setting-input-outer">
                                    <input
                                      className="setting-input-box"
                                      type="text"
                                      name="full_refund_amount"
                                      readOnly={true}
                                      value={
                                        this.state.getRefundData &&
                                        this.state.getRefundData.total_amount
                                          ? numberFormat(
                                              this.state.getRefundData
                                                .total_amount,
                                              "decimal",
                                            )
                                          : numberFormat(0, "decimal")
                                      }
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                )}
                              {this.state.getRefundData &&
                                this.state.refund_type === "partial" && (
                                  <div className="setting-input-outer">
                                    {availPayments && (
                                      <Select
                                        onChange={this.handlePaymentChange}
                                        name="partial_refund_ids"
                                        value={this.state.selectedPayments}
                                        isClearable
                                        isSearchable
                                        options={availPayments}
                                        isMulti={false}
                                        className={
                                          this.state.selectPaymentClass
                                        }
                                      />
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">
                                {this.state.globalLang.inv_select_a_reason}{" "}
                                <span className="required">*</span>
                              </div>
                              <div className="setting-input-outer">
                                <select
                                  className={this.state.selectAReasonClass}
                                  name="refund_reason"
                                  onChange={this.handleInputChange}
                                  value={this.state.refund_reason}
                                >
                                  <option value="">
                                    {this.state.globalLang.label_select}
                                  </option>
                                  <option value="returned_goods">
                                    {this.state.globalLang.inv_returned_goods}
                                  </option>
                                  <option value="accidental_charge">
                                    {
                                      this.state.globalLang
                                        .inv_accidental_charge
                                    }
                                  </option>
                                  <option value="canceled_order">
                                    {this.state.globalLang.inv_canceled_order}
                                  </option>
                                  <option value="custom">
                                    {this.state.globalLang.inv_custom}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.refund_type === "partial" &&
                          this.state.selectedPayments &&
                          this.state.selectedPayments.obj &&
                          this.state.selectedPayments.obj.payment_mode ===
                            "care_credit" && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  Refund Care Credit{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.careCreditAmountClass}
                                  type="text"
                                  name="care_credit_amount"
                                  value={this.state.care_credit_amount}
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}

                        {this.state.refund_type === "partial" &&
                          this.state.selectedPayments &&
                          this.state.selectedPayments.obj &&
                          this.state.selectedPayments.obj.payment_mode ===
                            "greensky" && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  Refund Greensky{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.greenskyAmountClass}
                                  type="text"
                                  name="greensky_amount"
                                  value={this.state.greensky_amount}
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}

                        {this.state.refund_type === "partial" &&
                          this.state.selectedPayments &&
                          this.state.selectedPayments.obj &&
                          this.state.selectedPayments.obj.payment_mode ===
                            "cash" && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  {this.state.globalLang.inv_refund_cash}{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.cashAmountClass}
                                  type="text"
                                  name="cash_amount"
                                  value={this.state.cash_amount}
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}
                        {this.state.refund_type === "partial" &&
                          this.ccPaymentModes.includes(
                            this.state.selectedPayments?.obj?.payment_mode.toLowerCase(),
                          ) && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  Refund Amount{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.ccAmountClass}
                                  type="text"
                                  name={this.ccPaymentModesAmountString}
                                  value={
                                    this.state[this.ccPaymentModesAmountString]
                                  }
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}

                        {this.state.refund_type === "partial" &&
                          this.state.selectedPayments &&
                          this.state.selectedPayments.obj &&
                          this.state.selectedPayments.obj.payment_mode ===
                            "wallet" && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  {this.state.globalLang.inv_refund_wallet}{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.walletAmountClass}
                                  type="text"
                                  name="wallet_amount"
                                  value={this.state.wallet_amount}
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}

                        {this.state.refund_type === "partial" &&
                          this.state.selectedPayments &&
                          this.state.selectedPayments.obj &&
                          this.state.selectedPayments.obj.payment_mode ===
                            "check" && (
                            <div className="row m-t-5">
                              <div className="col-xs-12 m-b-15">
                                <div className="new-field-label">
                                  {this.state.globalLang.refund_check}{" "}
                                  <span className="required">*</span>
                                </div>
                                <input
                                  className={this.state.checkAmountClass}
                                  type="text"
                                  name="check_amount"
                                  value={this.state.check_amount}
                                  onChange={this.handleInputChange}
                                  placeholder={this.getRefundAmountPlaceholder()}
                                />
                              </div>
                            </div>
                          )}

                        <div className={this.state.refundReasonClass}>
                          <div className="col-xs-12">
                            <div className="new-field-label">
                              {this.state.globalLang.inv_reason_for_refund}{" "}
                              <span className="required">*</span>
                            </div>
                            <textarea
                              className={this.state.reasonAreaClass}
                              name="text_reason_for_refund"
                              onChange={this.handleInputChange}
                              value={this.state.text_reason_for_refund}
                            ></textarea>
                          </div>
                        </div>

                        {this.state.invoiceData &&
                          this.state.invoiceData.invoice_data &&
                          this.state.invoiceData.invoice_data
                            .pos_invoice_items[0] &&
                          this.state.invoiceData.invoice_data
                            .pos_invoice_items[0]["product_type"] &&
                          this.state.invoiceData.invoice_data
                            .pos_invoice_items[0]["product_type"] !==
                            "monthly_membership" &&
                          ((this.state.selectedPayments &&
                            this.state.selectedPayments.obj &&
                            this.state.selectedPayments.obj.payment_mode) ||
                            this.state.refund_type === "full") && (
                            <div className="row m-t-10">
                              <div className="col-xs-12">
                                <div className="setting-field-outer">
                                  <div className="new-field-label">
                                    {this.state.globalLang.where_to_send_refund}
                                  </div>
                                  <div className="setting-input-outer m-b-10">
                                    {this.isSourceRefundAvailable && (
                                      <div className="basic-checkbox-outer">
                                        <input
                                          id="default"
                                          className="basic-form-checkbox"
                                          name="payment_refund_type"
                                          type="radio"
                                          value="default"
                                          checked={
                                            this.state.payment_refund_type ===
                                            "default"
                                              ? true
                                              : false
                                          }
                                          onChange={this.handleInputChange}
                                        />
                                        <label
                                          className="basic-form-text"
                                          htmlFor="default"
                                        >
                                          {this.state.globalLang.send_to_source}
                                        </label>
                                      </div>
                                    )}
                                    {this.state.isTouchMD == 1 &&
                                    this.state.plan_selected ==
                                      "practice_booking"
                                      ? ""
                                      : !this.isGuestPatient && (
                                          <div className="basic-checkbox-outer">
                                            <input
                                              id="cash_to_wallet"
                                              className="basic-form-checkbox"
                                              name="payment_refund_type"
                                              type="radio"
                                              value="cash_to_wallet"
                                              checked={
                                                this.state
                                                  .payment_refund_type ===
                                                "cash_to_wallet"
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.handleInputChange}
                                            />
                                            <label
                                              className="basic-form-text"
                                              htmlFor="cash_to_wallet"
                                            >
                                              {
                                                this.state.globalLang
                                                  .send_to_patient_wallet
                                              }
                                            </label>
                                          </div>
                                        )}
                                  </div>
                                  {this.isGiftCardNoticeVisible && (
                                    <p>
                                      {`The value of the Gift card will be added
                                      to the patient's wallet as dollar credit.`}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )}

                  {this.state.getRefundData &&
                    this.state.getRefundData.refund_options &&
                    this.state.getRefundData.refund_options.length === 0 && (
                      <div className="juvly-container">
                        <div className="row">
                          <div className="col-xs-12">
                            <p
                              style={{ fontSize: "18px", textAlign: "center" }}
                            >
                              {this.state.globalLang.inv_unable_to_refund}{" "}
                              {this.state.invoiceData.invoice_data
                                ?.has_rmd_redemptions
                                ? "Payment done via RepeatMD"
                                : this.state.globalLang
                                    .inv_payment_done_via_care_credit}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                {this.state.getRefundData &&
                  this.state.getRefundData.refund_options &&
                  this.state.getRefundData.refund_options.length > 0 && (
                    <div className="footer-static">
                      <input
                        type="submit"
                        className="new-blue-btn pull-right"
                        value="Issue Refund"
                      />
                    </div>
                  )}
              </div>
            </form>
          </div>

          {this.state.showLoader === false && (
            <div
              className={
                this.state.showPaymentHistory === true ? "modalOverlay" : ""
              }
            >
              <div
                className={
                  this.state.showPaymentHistory === true
                    ? "small-popup-outer appointment-detail-main displayBlock"
                    : "small-popup-outer appointment-detail-main no-display"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.globalLang.inv_invoice_no_uppercase} :{" "}
                    {this.state.historyData &&
                      this.state.historyData.invoice_number &&
                      this.state.historyData.invoice_number}
                  </div>
                  <a
                    onClick={() => this.hidePaymentHistory()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                <div className="small-popup-content">
                  <div className="juvly-container no-padding-bottom">
                    <div className="prescription-content">
                      <div className="doc-section">
                        <span className="juvly-subtitle m-b-10">
                          {this.state.globalLang.inv_payments}
                        </span>

                        <div className="table-responsive m-b-30">
                          <table className="table-updated juvly-table no-hover">
                            <thead className="table-updated-thead">
                              <tr>
                                <th className="col-xs-3 table-updated-th">
                                  {this.state.globalLang.inv_payments_mode}
                                </th>
                                <th className="col-xs-3 table-updated-th text-right">
                                  {this.state.globalLang.label_date}
                                </th>
                                <th className="col-xs-3 table-updated-th text-right">
                                  {this.state.globalLang.label_amount}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ajax_body">
                              {this.state.showLoader === false &&
                                this.state.historyData &&
                                this.state.historyData.pos_transaction &&
                                this.state.historyData.pos_transaction
                                  .pos_transactions_payments &&
                                this.state.historyData.pos_transaction
                                  .pos_transactions_payments.length > 0 &&
                                this.state.historyData.pos_transaction.pos_transactions_payments.map(
                                  (obj, idx) => {
                                    let paymentMode = "";
                                    let paymentDate = "";
                                    let totalAmount = "";

                                    if (obj.payment_mode === "cc") {
                                      if (
                                        obj.cc_type.toLowerCase() === "link"
                                      ) {
                                        paymentMode = "Link";
                                      } else if (
                                        obj.cc_type.toLowerCase() === "klarna"
                                      ) {
                                        paymentMode = "Klarna";
                                      } else {
                                        paymentMode =
                                          this.state.globalLang
                                            .inv_credit_card_ending +
                                          " " +
                                          obj.cc_number;
                                      }
                                    } else if (obj.payment_mode === "wallet") {
                                      paymentMode =
                                        this.state.globalLang.inv_wallet;
                                    } else if (
                                      obj.payment_mode === "care_credit"
                                    ) {
                                      paymentMode =
                                        this.state.globalLang.inv_care_credit;
                                    } else if (obj.payment_mode == "greensky") {
                                      paymentMode = "Greensky";
                                    } else if (obj.payment_mode === "cherry") {
                                      paymentMode = "Cherry";
                                    } else if (obj.payment_mode === "check") {
                                      paymentMode = obj.cheque_no
                                        ? this.state.salesLang
                                            .frontdesk_cheque +
                                          " #" +
                                          obj.cheque_no
                                        : this.state.salesLang.frontdesk_cheque;
                                    } else if (
                                      obj.payment_mode === "gift_card"
                                    ) {
                                      paymentMode = "Gift Card";
                                    } else if (
                                      obj.payment_mode === "repeat_md"
                                    ) {
                                      paymentMode = "RepeatMD Payment";
                                    } else {
                                      paymentMode =
                                        this.state.globalLang.inv_cash;
                                    }
                                    totalAmount = numberFormat(
                                      obj.total_amount,
                                      "currency",
                                      2,
                                    );
                                    if (
                                      obj.created &&
                                      obj.created != "0000-00-00 00:00:00"
                                    ) {
                                      paymentDate = showFormattedDate(
                                        obj.created,
                                        false,
                                        "MMMM DD, Y",
                                      );
                                    } else {
                                      paymentDate = showFormattedDate(
                                        this.state.historyData.pos_transaction
                                          .transaction_datetime,
                                        false,
                                        "MMMM DD, Y",
                                      );
                                    }

                                    return (
                                      <tr
                                        key={idx}
                                        className="table-updated-tr"
                                      >
                                        <td className="table-updated-td">
                                          {paymentMode}
                                        </td>
                                        <td className="table-updated-td text-right">
                                          {paymentDate}
                                        </td>
                                        <td className="table-updated-td text-right">
                                          {totalAmount}
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                            </tbody>
                          </table>
                          <div
                            className={
                              this.state.showLoader === false &&
                              this.state.historyData &&
                              this.state.historyData.pos_transaction &&
                              this.state.historyData.pos_transaction
                                .pos_transactions_payments &&
                              this.state.historyData.pos_transaction
                                .pos_transactions_payments.length > 0
                                ? "no-record no-display"
                                : "no-record"
                            }
                          >
                            {this.state.globalLang.sorry_no_record_found}
                          </div>
                        </div>

                        <span className="juvly-subtitle juvly-subtitle m-b-10">
                          {this.state.globalLang.inv_refunds}
                        </span>

                        <div className="table-responsive">
                          <table className="table-updated juvly-table no-hover">
                            <thead className="table-updated-thead">
                              <tr>
                                <th className="col-xs-3 table-updated-th">
                                  {this.state.globalLang.inv_payments_mode}
                                </th>
                                <th className="col-xs-3 table-updated-th text-right">
                                  {this.state.globalLang.label_date}
                                </th>
                                <th className="col-xs-3 table-updated-th text-right">
                                  {this.state.globalLang.label_amount}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ajax_body">
                              {refunds.map((refund, index) => (
                                <tr
                                  key={`refund-${refund.amount}-${index}`}
                                  className="table-updated-tr"
                                >
                                  <td className="table-updated-td">
                                    {refund.paymentMode}
                                    <br />
                                    {
                                      this.state.globalLang.inv_refund_reason
                                    }: {refund.reason}
                                  </td>
                                  <td className="table-updated-td text-right">
                                    {refund.date}
                                  </td>
                                  <td className="table-updated-td text-right">
                                    {refund.amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div
                            className={
                              this.state.showLoader === false &&
                              refunds.length > 0 &&
                              showRefund !== true
                                ? "no-record no-display"
                                : "no-record"
                            }
                          >
                            {this.state.globalLang.sorry_no_record_found}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="juvly-section full-width m-t-15">
            <div className="juvly-container">
              <div className="juvly-title m-b-40">
                {this.state.globalLang.inv_invoice_preview}
                <Link to={returnTo} className="pull-right">
                  <img alt="" src="../../../../images/close.png" />
                </Link>
              </div>

              <div className="invoice-preview-outer">
                <center>
                  <table
                    id="print_area"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    height="100%"
                    width={700}
                  >
                    <tbody>
                      <tr>
                        <td align="center" valign="top">
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width={700}
                          >
                            <tbody>
                              <tr>
                                <td valign="top">
                                  <table
                                    border={0}
                                    cellPadding={5}
                                    cellSpacing={0}
                                    width={700}
                                  >
                                    <tbody>
                                      <tr>
                                        <td valign="top" width={400}>
                                          <div style={{ padding: "5px 0px" }}>
                                            {this.state.showLoader ===
                                              false && (
                                              <img
                                                alt=""
                                                src={logoSrc}
                                                style={{ height: "70px" }}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td
                                          valign="top"
                                          width={400}
                                          align="right"
                                        >
                                          <div
                                            style={{
                                              fontSize: "24px",
                                              textAlign: "right",
                                              paddingTop: "22px",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            {inoiceTitle}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width={700}
                          >
                            <tbody>
                              <tr>
                                <td align="center" valign="top">
                                  <table
                                    border={0}
                                    cellPadding={0}
                                    cellSpacing={0}
                                    width={700}
                                  >
                                    <tbody>
                                      <tr>
                                        <td colSpan={3} valign="top">
                                          <table
                                            border={0}
                                            cellPadding={10}
                                            cellSpacing={0}
                                            width={700}
                                          >
                                            <tbody>
                                              <tr>
                                                <td valign="top">
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      lineHeight: "22px",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    <div className="d-flex align-center gap-8">
                                                      {this.state.invoiceData &&
                                                      this.state.invoiceData
                                                        .invoice_data &&
                                                      this.state.invoiceData
                                                        .invoice_data.clinic
                                                        ? capitalizeFirstLetter(
                                                            shortenClinicName(
                                                              this.state
                                                                .invoiceData
                                                                .invoice_data
                                                                .clinic
                                                                .clinic_name,
                                                            ),
                                                          )
                                                        : ""}
                                                      {isChangeClinicAllowed && (
                                                        <ChangeClinic
                                                          invoiceId={
                                                            this.state.invoiceID
                                                          }
                                                          clinicId={
                                                            this.state
                                                              .invoiceData
                                                              ?.invoice_data
                                                              ?.clinic?.id
                                                          }
                                                          onSuccess={() =>
                                                            this.fetchInvoiceDetails(
                                                              this.state
                                                                .invoiceID,
                                                              this.state
                                                                .clientID,
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data.clinic
                                                      ? this.state.invoiceData
                                                          .invoice_data.clinic
                                                          .contact_no
                                                      : ""}
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td valign="top">
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#000000",
                                                      padding: "10px 0px 3px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_invoice_to
                                                    }
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: "20px",
                                                      color: "#777777",
                                                      fontWeight: 400,
                                                    }}
                                                  >
                                                    {this.patientName ||
                                                      displayName(
                                                        this.state.invoiceData
                                                          ?.invoice_data
                                                          ?.patient,
                                                      )}
                                                  </div>
                                                </td>
                                              </tr>
                                              {address ? (
                                                <tr>
                                                  <td valign="top">
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#000000",
                                                        padding: "10px 0px 3px",
                                                      }}
                                                    >
                                                      {
                                                        this.state.globalLang
                                                          .label_address
                                                      }
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#777777",
                                                        fontWeight: 400,
                                                      }}
                                                    >
                                                      {address}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : (
                                                <tr></tr>
                                              )}
                                              <tr>
                                                <td valign="top" width={400}>
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#000000",
                                                      padding: "10px 0px 3px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .label_email
                                                    }
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      fontWeight: 400,
                                                    }}
                                                  >
                                                    {this.patientEmail || ""}
                                                  </div>
                                                </td>
                                                <td
                                                  valign="top"
                                                  width={400}
                                                  align="right"
                                                >
                                                  <table
                                                    width={260}
                                                    style={{
                                                      float: "right",
                                                      fontSize: "14px",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="text-capitalize"
                                                        >
                                                          {
                                                            this.state
                                                              .globalLang
                                                              .inv_invoice_no
                                                          }
                                                          :
                                                        </td>
                                                        <td
                                                          style={{
                                                            color: "#777777",
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          {this.state
                                                            .invoiceData &&
                                                          this.state.invoiceData
                                                            .invoice_data &&
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .invoice_number
                                                            ? this.state
                                                                .invoiceData
                                                                .invoice_data
                                                                .invoice_number
                                                            : ""}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td align="left">
                                                          {
                                                            this.state
                                                              .globalLang
                                                              .inv_invoice_date
                                                          }
                                                          :
                                                        </td>
                                                        <td
                                                          style={{
                                                            color: "#777777",
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          {this.state
                                                            .invoiceData &&
                                                          this.state.invoiceData
                                                            .invoice_data &&
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .created
                                                            ? this.state
                                                                .invoiceData
                                                                .invoice_data
                                                                .created
                                                            : ""}
                                                        </td>
                                                      </tr>
                                                      {this.state.invoiceData &&
                                                        this.state.invoiceData
                                                          .invoice_data &&
                                                        this.state.invoiceData
                                                          .invoice_data
                                                          .payment_datetime &&
                                                        this.state.invoiceData
                                                          .invoice_data
                                                          .invoice_status !==
                                                          "draft" && (
                                                          <tr>
                                                            <td align="left">
                                                              {
                                                                this.state
                                                                  .globalLang
                                                                  .inv_payment_on
                                                              }
                                                              :
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  "#777777",
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              {this.state
                                                                .invoiceData &&
                                                              this.state
                                                                .invoiceData
                                                                .invoice_data &&
                                                              this.state
                                                                .invoiceData
                                                                .invoice_data
                                                                .payment_datetime
                                                                ? this.state
                                                                    .invoiceData
                                                                    .invoice_data
                                                                    .payment_datetime
                                                                : ""}
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3} valign="top">
                                  <table
                                    border={0}
                                    cellPadding={10}
                                    cellSpacing={0}
                                    width={700}
                                    style={{ marginTop: "25px" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #dddddd",
                                          }}
                                          width="230"
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#000000",
                                              textTransform: "uppercase",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            Item description
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #dddddd",
                                          }}
                                          align="right"
                                          width="100"
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#000000",
                                              textTransform: "uppercase",
                                              textAlign: "right",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            Price
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #dddddd",
                                          }}
                                          align="right"
                                          width="150"
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#000000",
                                              textTransform: "uppercase",
                                              textAlign: "center",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            Tip
                                          </div>
                                        </td>
                                        <td
                                          id="proNameHead"
                                          style={{
                                            borderBottom: "1px solid #dddddd",
                                          }}
                                          align="right"
                                          width="200"
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#000000",
                                              textTransform: "uppercase",
                                              textAlign: "right",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            Employee
                                          </div>
                                        </td>
                                      </tr>
                                      {/* Added check for medical supplies */}
                                      {this.state.invoiceData &&
                                        this.state.invoiceData.invoice_data &&
                                        this.state.invoiceData.invoice_data
                                          .pos_invoice_items &&
                                        this.state.invoiceData.invoice_data
                                          .pos_invoice_items.length > 0 &&
                                        this.state.invoiceData.invoice_data.pos_invoice_items.map(
                                          (obj, idx) => {
                                            if (
                                              obj.product_type != "others" ||
                                              !obj.product ||
                                              obj.product.product_type_label !=
                                                "medical_supplies"
                                            ) {
                                              const chartingPackageType =
                                                obj.discount_package
                                                  ?.charting_package_type;
                                              let productType =
                                                obj.product_type;
                                              let productName = "";
                                              let usedProduct = "";
                                              let invoiceItemID = obj.id;
                                              let userID = obj.user_id;
                                              let cusProName =
                                                obj.custom_product_name;

                                              if (productType === "custom") {
                                                productName =
                                                  obj.custom_product_name;
                                              } else if (
                                                productType == "service"
                                              ) {
                                                let oldPrice =
                                                  obj.total_product_price /
                                                  obj.product_units;
                                                oldPrice = numberFormat(
                                                  oldPrice,
                                                  "currency",
                                                );
                                                let proName =
                                                  obj.service &&
                                                  obj.service.name
                                                    ? obj.service.name
                                                    : obj.custom_product_name
                                                    ? obj.custom_product_name
                                                    : "";
                                                productName =
                                                  obj.product_units +
                                                  " " +
                                                  (obj.product_units == 1
                                                    ? "service of"
                                                    : "services of") +
                                                  " " +
                                                  proName +
                                                  " " +
                                                  this.state.globalLang
                                                    .inv_priced +
                                                  " " +
                                                  oldPrice;
                                              } else if (
                                                productType === "others" ||
                                                productType === "injectable"
                                              ) {
                                                let oldPrice =
                                                  obj.total_product_price /
                                                  obj.product_units;
                                                oldPrice = numberFormat(
                                                  oldPrice,
                                                  "currency",
                                                );
                                                let proName =
                                                  obj.product &&
                                                  obj.product.product_name
                                                    ? obj.product.product_name
                                                    : "";
                                                if (obj.charting_package) {
                                                  productName =
                                                    obj.product_units +
                                                    " " +
                                                    this.state.globalLang
                                                      .inv_units_of +
                                                    " " +
                                                    (obj.charting_package
                                                      ? obj.charting_package
                                                          .name
                                                      : "") +
                                                    " - " +
                                                    proName +
                                                    " " +
                                                    this.state.globalLang
                                                      .inv_priced +
                                                    " " +
                                                    oldPrice;
                                                } else {
                                                  productName =
                                                    obj.product_units +
                                                    " " +
                                                    this.state.globalLang
                                                      .inv_units_of +
                                                    " " +
                                                    proName +
                                                    " " +
                                                    this.state.globalLang
                                                      .inv_priced +
                                                    " " +
                                                    oldPrice;
                                                }
                                              } else if (
                                                productType === "package"
                                              ) {
                                                let oldPrice =
                                                  obj.total_product_price /
                                                  obj.product_units;
                                                oldPrice = numberFormat(
                                                  oldPrice,
                                                  "currency",
                                                );
                                                productName =
                                                  obj.product_units +
                                                  " " +
                                                  this.state.globalLang
                                                    .inv_units_of +
                                                  " " +
                                                  (obj.discount_package &&
                                                  obj.discount_package.name
                                                    ? obj.discount_package.name
                                                    : "") +
                                                  " " +
                                                  this.state.globalLang
                                                    .inv_priced +
                                                  " " +
                                                  oldPrice;

                                                if (
                                                  this.state.invoiceData &&
                                                  this.state.invoiceData
                                                    .package_products_data &&
                                                  this.state.invoiceData
                                                    .package_products_data[
                                                    obj.id
                                                  ]
                                                ) {
                                                  this.state.invoiceData.package_products_data[
                                                    obj.id
                                                  ].map((pobj, pidx) => {
                                                    if (pidx === 0) {
                                                      usedProduct = pobj.product
                                                        ? pobj.product
                                                            .product_name
                                                        : "";
                                                    } else {
                                                      usedProduct +=
                                                        pobj.product
                                                          ? ", " +
                                                            pobj.product
                                                              .product_name
                                                          : "";
                                                    }
                                                  });
                                                }
                                              } else if (
                                                productType === "egiftcard"
                                              ) {
                                                productName = `Egift Card Dollar Value: ${numberFormat(
                                                  giftCardsAmountsByItemId[
                                                    obj.id
                                                  ]?.amount || 0,
                                                  "currency",
                                                )}`;
                                              } else if (
                                                productType ===
                                                "consultation_fee"
                                              ) {
                                                productName =
                                                  this.state.globalLang
                                                    .inv_consultation_fee;
                                              } else if (
                                                productType === "treatment_plan"
                                              ) {
                                                productName =
                                                  this.state.globalLang
                                                    .inv_treatment_plan;
                                              } else if (
                                                productType ===
                                                  "monthly_membership" &&
                                                cusProName ===
                                                  "monthly_membership"
                                              ) {
                                                productName =
                                                  this.state.globalLang
                                                    .inv_monthly_membership_fee;
                                              } else if (
                                                productType ===
                                                  "monthly_membership" &&
                                                cusProName ===
                                                  "one_time_setup_fee"
                                              ) {
                                                productName =
                                                  this.state.globalLang
                                                    .inv_one_time_setup_fee;
                                              } else if (
                                                productType ===
                                                  "monthly_membership" &&
                                                cusProName ===
                                                  "yearly_membership"
                                              ) {
                                                productName =
                                                  "Yearly Membership Fee";
                                              }

                                              const tipableProviders =
                                                this.state.invoiceData
                                                  ?.all_employees?.length > 0 &&
                                                this.state.invoiceData?.all_employees
                                                  ?.filter(
                                                    (el) => el.is_tips_enabled,
                                                  )
                                                  .map((employee) => {
                                                    return {
                                                      value: employee.id,
                                                      label:
                                                        displayName(employee),
                                                    };
                                                  });

                                              return (
                                                <tr
                                                  className={
                                                    productType ===
                                                      "monthly_membership" &&
                                                    cusProName ===
                                                      "one_time_setup_fee" &&
                                                    obj.total_product_price == 0
                                                      ? "no-display"
                                                      : ""
                                                  }
                                                  key={idx}
                                                >
                                                  <td
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                    }}
                                                  >
                                                    <div
                                                      ref={this.invoiceItem}
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#777777",
                                                        padding: "5px 0px",
                                                        minHeight: "50px",
                                                      }}
                                                    >
                                                      {productName}
                                                      <br />
                                                      {giftCardsAmountsByItemId[
                                                        obj.id
                                                      ]?.code
                                                        ? " (" +
                                                          giftCardsAmountsByItemId[
                                                            obj.id
                                                          ]?.code +
                                                          ")"
                                                        : ""}
                                                      {chartingPackageType !==
                                                        "collective_level_recipe" &&
                                                      usedProduct !== ""
                                                        ? "(" +
                                                          usedProduct +
                                                          ")"
                                                        : ""}
                                                    </div>

                                                    {obj.per_unit_discount_text ? (
                                                      <div
                                                        style={{
                                                          fontSize: "13px",
                                                          color: "#777777",
                                                          padding: "5px 0px",
                                                          fontStyle: "italic",
                                                        }}
                                                      >
                                                        {
                                                          obj.per_unit_discount_text
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {Boolean(
                                                      obj
                                                        .repeat_md_redeemed_items
                                                        ?.length,
                                                    ) &&
                                                      obj.repeat_md_redeemed_items?.map(
                                                        (item) => (
                                                          <div
                                                            key={item.id}
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "#777777",
                                                              padding:
                                                                "5px 0px",
                                                              fontStyle:
                                                                "italic",
                                                              minHeight: "50px",
                                                            }}
                                                          >
                                                            {item.quantity}{" "}
                                                            unit(s) of RepeatMD
                                                            - $
                                                            {
                                                              item.foreign_wallet_item_name
                                                            }{" "}
                                                            matched
                                                          </div>
                                                        ),
                                                      )}
                                                  </td>
                                                  <td
                                                    align="right"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        classes.productPrice
                                                      }
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#777777",
                                                        textTransform:
                                                          "uppercase",
                                                        textAlign: "right",
                                                        padding: "5px 0px",
                                                        height: "auto",
                                                        minHeight: "50px",
                                                      }}
                                                    >
                                                      {obj.total_product_price
                                                        ? numberFormat(
                                                            obj.total_product_price,
                                                            "currency",
                                                          )
                                                        : numberFormat(
                                                            0,
                                                            "currency",
                                                          )}
                                                    </div>

                                                    {obj.per_unit_discount_text ? (
                                                      <div
                                                        style={{
                                                          fontSize: "13px",
                                                          color: "#777777",
                                                          textTransform:
                                                            "uppercase",
                                                          textAlign: "right",
                                                          padding: "5px 0px",
                                                          fontStyle: "italic",
                                                        }}
                                                      >
                                                        (
                                                        {obj.unit_discount_type &&
                                                        obj.unit_discount_type ===
                                                          "complimentry"
                                                          ? numberFormat(
                                                              obj.total_product_price,
                                                              "currency",
                                                            )
                                                          : obj.per_unit_discount_amount
                                                          ? numberFormat(
                                                              obj.per_unit_discount_amount,
                                                              "currency",
                                                            )
                                                          : numberFormat(
                                                              0,
                                                              "currency",
                                                            )}
                                                        )
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {Boolean(
                                                      obj
                                                        .repeat_md_redeemed_items
                                                        ?.length,
                                                    ) &&
                                                      obj.repeat_md_redeemed_items.map(
                                                        (item) => (
                                                          <div
                                                            key={item.id}
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "#777777",
                                                              textTransform:
                                                                "uppercase",
                                                              textAlign:
                                                                "right",
                                                              padding:
                                                                "5px 0px",
                                                              fontStyle:
                                                                "italic",
                                                              minHeight: "50px",
                                                            }}
                                                          >
                                                            {`(${numberFormat(
                                                              item.rmd_redeem_price,
                                                              "currency",
                                                            )})`}
                                                          </div>
                                                        ),
                                                      )}
                                                  </td>
                                                  <td
                                                    align="center"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#777777",
                                                        textTransform:
                                                          "uppercase",
                                                        textAlign: "center",
                                                        padding: "5px 0px",
                                                        maxWidth: "70px",
                                                        minHeight: "50px",
                                                      }}
                                                    >
                                                      {!this.state
                                                        .tipEditMode ? (
                                                        numberFormat(
                                                          this.state
                                                            .tipsPerItem[
                                                            obj.id
                                                          ],
                                                          "currency",
                                                        )
                                                      ) : (
                                                        <InputCurrency
                                                          name="tipsPerItem"
                                                          size="small"
                                                          prefix={getCurrencySymbol()}
                                                          value={
                                                            this.state
                                                              .tipsPerItem[
                                                              obj.id
                                                            ]
                                                          }
                                                          isDisabled={
                                                            !this.isUserTipEnabled(
                                                              obj.user,
                                                            )
                                                          }
                                                          onChange={(value) =>
                                                            this.handleTipsPerItemChange(
                                                              value,
                                                              obj.id,
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="proName"
                                                    id={`proName_` + idx}
                                                    align="right"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {showNameOnly &&
                                                      showNameOnly ===
                                                        "true" && (
                                                        <div>
                                                          {this.state
                                                            .invoiceData &&
                                                            this.state
                                                              .invoiceData
                                                              .all_employees &&
                                                            this.state
                                                              .invoiceData
                                                              .all_employees
                                                              .length > 0 &&
                                                            this.state.invoiceData.all_employees.map(
                                                              (iobj) => {
                                                                if (
                                                                  iobj.id ===
                                                                  userID
                                                                ) {
                                                                  return displayName(
                                                                    iobj,
                                                                  );
                                                                }
                                                              },
                                                            )}
                                                        </div>
                                                      )}

                                                    <div
                                                      style={{ width: "210px" }}
                                                    >
                                                      {checkIfPermissionAllowed(
                                                        "invoice-provider-change",
                                                      ) ? (
                                                        defaultProviders && (
                                                          <Select
                                                            onChange={(value) =>
                                                              this.handleProviderChange(
                                                                value,
                                                                invoiceItemID,
                                                              )
                                                            }
                                                            name={
                                                              "providers_" +
                                                              obj.id
                                                            }
                                                            value={
                                                              this.state
                                                                .selectedProvider[
                                                                invoiceItemID
                                                              ]
                                                            }
                                                            isClearable={false}
                                                            isSearchable
                                                            isDisabled={
                                                              this.state
                                                                .tipEditMode
                                                            }
                                                            options={
                                                              parseFloat(
                                                                this.state
                                                                  .tipsPerItem[
                                                                  obj.id
                                                                ],
                                                              )
                                                                ? tipableProviders
                                                                : defaultProviders
                                                            }
                                                            isMulti={false}
                                                          />
                                                        )
                                                      ) : (
                                                        <div>
                                                          {this.state
                                                            .invoiceData &&
                                                            this.state
                                                              .invoiceData
                                                              .all_employees &&
                                                            this.state
                                                              .invoiceData
                                                              .all_employees
                                                              .length > 0 &&
                                                            this.state.invoiceData.all_employees.map(
                                                              (iobj) => {
                                                                if (
                                                                  iobj.id ===
                                                                  userID
                                                                ) {
                                                                  return displayName(
                                                                    iobj,
                                                                  );
                                                                }
                                                              },
                                                            )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          },
                                        )}
                                    </tbody>
                                  </table>

                                  {this.hasRepeatMdRedemptions && (
                                    <RepeatMdRedemptions
                                      repeatMdRedemptionsItems={
                                        this.repeatMdRedemptionsItems
                                      }
                                    />
                                  )}

                                  {this.state.invoiceData &&
                                    this.state.invoiceData.invoice_data &&
                                    (this.state.invoiceData.invoice_data
                                      .aspire_discount > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .aspire_certificates_discount > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .bd_discount > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .xperience_discount > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .evolus_discount > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .prepayment_adjustment > 0 ||
                                      this.state.invoiceData.invoice_data
                                        .egift_Card_amount > 0) && (
                                      <table
                                        border={0}
                                        cellPadding={10}
                                        cellSpacing={0}
                                        width={700}
                                        style={{ marginTop: "25px" }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "1px solid #dddddd",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: "13px",
                                                  color: "#000000",
                                                  textTransform: "uppercase",
                                                  padding: "10px 0px",
                                                }}
                                              >
                                                {
                                                  this.state.globalLang
                                                    .inv_redemptions
                                                }
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "1px solid #dddddd",
                                              }}
                                              align="right"
                                            >
                                              <div
                                                style={{
                                                  fontSize: "13px",
                                                  color: "#000000",
                                                  textTransform: "uppercase",
                                                  textAlign: "right",
                                                  padding: "10px 0px",
                                                }}
                                              >
                                                {
                                                  this.state.globalLang
                                                    .label_amount
                                                }
                                              </div>
                                            </td>
                                          </tr>
                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .aspire_discount > 0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_aspire_discount
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .aspire_discount
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .aspire_discount,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}

                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .aspire_certificates_discount >
                                              0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_aspire_certificates_discount
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .aspire_certificates_discount
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .aspire_certificates_discount,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}

                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .bd_discount > 0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_bd_discount
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data.bd_discount
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .bd_discount,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .xperience_discount > 0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_xperience_discount
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .xperience_discount
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .xperience_discount,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}

                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .evolus_discount > 0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_evolus_discount
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .evolus_discount
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .evolus_discount,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}

                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .prepayment_adjustment > 0 && (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#777777",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    {
                                                      this.state.globalLang
                                                        .inv_wallet_debits
                                                    }
                                                  </div>
                                                </td>
                                                <td
                                                  align="right"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #dddddd",
                                                    padding: "10px 0px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: "13px",
                                                      color: "#777777",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .prepayment_adjustment
                                                      ? numberFormat(
                                                          this.state.invoiceData
                                                            .invoice_data
                                                            .prepayment_adjustment,
                                                          "currency",
                                                        )
                                                      : numberFormat(
                                                          0,
                                                          "currency",
                                                        )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .egift_Card_amount > 0 && (
                                              <>
                                                {this.state.invoiceData.invoice_data.egift_cards_redemptions.map(
                                                  (item, i) => (
                                                    <tr key={i}>
                                                      <td
                                                        style={{
                                                          borderBottom:
                                                            "0.5px solid #dddddd",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontSize: "14px",
                                                            color: "#777777",
                                                            padding: "10px 0px",
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .globalLang
                                                              .inv_eGiftcard_amount
                                                          }{" "}
                                                          with redemption code{" "}
                                                          {
                                                            item
                                                              .e_gift_card_voucher
                                                              .redemption_code
                                                          }
                                                        </div>
                                                      </td>
                                                      <td
                                                        align="right"
                                                        style={{
                                                          borderBottom:
                                                            "0.5px solid #dddddd",
                                                          padding: "10px 0px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            fontSize: "13px",
                                                            color: "#777777",
                                                            textTransform:
                                                              "uppercase",
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          {numberFormat(
                                                            item.amount,
                                                            "currency",
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ),
                                                )}
                                              </>
                                            )}
                                        </tbody>
                                      </table>
                                    )}
                                  <table
                                    border={0}
                                    cellPadding={10}
                                    cellSpacing={0}
                                    width={700}
                                    style={{
                                      marginTop: "25px",
                                      border: "1px solid #000",
                                      marginBottom: "25px",
                                    }}
                                  >
                                    <tbody>
                                      <tr className={classes.summaryRow}>
                                        <td
                                          width={120}
                                          style={{ padding: "10px" }}
                                          className={classes.summaryData}
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#777777",
                                            }}
                                          >
                                            SUBTOTAL
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "17px",
                                              color: "#000000",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {numberFormat(
                                              this.state.invoiceData
                                                ?.invoice_data?.sub_total,
                                              "currency",
                                            )}
                                          </div>
                                        </td>
                                        {/*Discount Section*/}

                                        <td
                                          width={180}
                                          style={{
                                            padding: "10px",
                                            minWidth: "180px",
                                          }}
                                          className={classes.summaryData}
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#777777",
                                            }}
                                          >
                                            DISCOUNTS, DEBITS & TRANSFER CREDIT
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "17px",
                                              color: "#000000",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {numberFormat(
                                              this
                                                .discountDebitsAndTransferCredit,
                                              "currency",
                                            )}
                                          </div>
                                        </td>

                                        <td
                                          width={120}
                                          style={{ padding: "10px" }}
                                          className={classes.summaryData}
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#777777",
                                              minWidth: "100px",
                                            }}
                                          >
                                            {
                                              this.state.globalLang
                                                .inv_tax_uppercase
                                            }
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "17px",
                                              color: "#000000",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .total_tax
                                              ? numberFormat(
                                                  this.state.invoiceData
                                                    .invoice_data.total_tax,
                                                  "currency",
                                                )
                                              : numberFormat(0, "currency")}
                                          </div>
                                        </td>
                                        <td
                                          width={120}
                                          className={classes.summaryData}
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#777777",
                                            }}
                                          >
                                            {
                                              this.state.globalLang
                                                .inv_tip_uppercase
                                            }
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "17px",
                                              color: "#000000",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .tip_amount
                                              ? numberFormat(
                                                  this.state.invoiceData
                                                    .invoice_data.tip_amount,
                                                  "currency",
                                                )
                                              : numberFormat(0, "currency")}
                                          </div>
                                        </td>
                                        <td
                                          width={220}
                                          style={{
                                            background: "#000000",
                                            padding: "10px",
                                          }}
                                          align="right"
                                          className={classes.summaryData}
                                        >
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#ffffff",
                                              textAlign: "right",
                                            }}
                                          >
                                            TOTAL DUE
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "17px",
                                              color: "#ffffff",
                                              fontWeight: 400,
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .total_amount
                                              ? numberFormat(
                                                  this.state.invoiceData
                                                    .invoice_data.total_amount,
                                                  "currency",
                                                )
                                              : numberFormat(0, "currency")}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {!this.state.tipEditMode ? (
                                    this.isTipDistributable && (
                                      <button
                                        className="new-blue-btn no-margin"
                                        onClick={() =>
                                          this.setState({ tipEditMode: true })
                                        }
                                      >
                                        Edit Tip
                                      </button>
                                    )
                                  ) : (
                                    <div>
                                      <button
                                        className="line-btn no-margin"
                                        onClick={() =>
                                          this.handleCancelTipsPerItem()
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="new-blue-btn"
                                        onClick={() =>
                                          this.handleSaveTipsPerItem()
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}

                                  {isMonthlyMember === 1 ? (
                                    <table
                                      border={0}
                                      cellPadding={10}
                                      cellSpacing={0}
                                      width={700}
                                      style={{ marginTop: "25px" }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .inv_membership_benefits_uppercase
                                              }
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                            align="right"
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                textAlign: "right",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .label_amount
                                              }
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "14px",
                                                color: "#777777",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .inv_membership_savings_on_todays_visit
                                              }
                                            </div>
                                          </td>
                                          <td
                                            align="right"
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#777777",
                                                textTransform: "uppercase",
                                                textAlign: "right",
                                              }}
                                            >
                                              {this.state.invoiceData &&
                                              this.state.invoiceData
                                                .invoice_data
                                                ? numberFormat(
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .membership_benefit,
                                                    "currency",
                                                  )
                                                : numberFormat(0, "currency")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "14px",
                                                color: "#777777",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .inv_your_membership_savings_this_year
                                              }
                                            </div>
                                          </td>
                                          <td
                                            align="right"
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                              padding: "10px 0px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#777777",
                                                textTransform: "uppercase",
                                                textAlign: "right",
                                              }}
                                            >
                                              {this.state.invoiceData &&
                                              this.state.invoiceData
                                                .invoice_data &&
                                              this.state.invoiceData
                                                .invoice_data.patient
                                                ? numberFormat(
                                                    this.state.invoiceData
                                                      .invoice_data.patient
                                                      .membership_benefits_this_year,
                                                    "currency",
                                                  )
                                                : numberFormat(0, "currency")}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.invoiceData &&
                                  this.state.invoiceData.invoice_data &&
                                  this.state.invoiceData.invoice_data
                                    .pos_transaction &&
                                  this.state.invoiceData.invoice_data
                                    .pos_transaction
                                    .pos_transactions_payments &&
                                  this.totalTransactionsPayments ? (
                                    <table
                                      border={0}
                                      cellPadding={10}
                                      cellSpacing={0}
                                      width={700}
                                      style={{ marginTop: "25px" }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .inv_payments
                                              }
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                            align="right"
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                textAlign: "right",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {
                                                this.state.globalLang
                                                  .label_amount
                                              }
                                            </div>
                                          </td>
                                        </tr>

                                        {this.state.invoiceData &&
                                          this.state.invoiceData.invoice_data &&
                                          this.state.invoiceData.invoice_data
                                            .pos_transaction &&
                                          this.state.invoiceData.invoice_data
                                            .pos_transaction
                                            .pos_transactions_payments &&
                                          this.state.invoiceData.invoice_data
                                            .pos_transaction
                                            .pos_transactions_payments.length >
                                            0 &&
                                          this.state.invoiceData.invoice_data.pos_transaction.pos_transactions_payments.map(
                                            (obj, idx) => {
                                              let paymentMode = "";

                                              if (obj.payment_mode === "cc") {
                                                if (
                                                  obj.cc_type.toLowerCase() ===
                                                  "link"
                                                ) {
                                                  paymentMode = "Link";
                                                } else if (
                                                  obj.cc_type.toLowerCase() ===
                                                  "klarna"
                                                ) {
                                                  paymentMode = "Klarna";
                                                } else {
                                                  paymentMode =
                                                    this.state.globalLang
                                                      .inv_credit_card_ending +
                                                    " " +
                                                    obj.cc_number;
                                                }
                                              } else if (
                                                obj.payment_mode ===
                                                "care_credit"
                                              ) {
                                                paymentMode =
                                                  this.state.globalLang
                                                    .inv_care_credit;

                                                if (obj.care_credit_note) {
                                                  paymentMode +=
                                                    " " +
                                                    this.state.globalLang
                                                      .label_note +
                                                    " - " +
                                                    obj.care_credit_note;
                                                }
                                              } else if (
                                                obj.payment_mode === "greensky"
                                              ) {
                                                paymentMode = "Greensky";

                                                if (obj.greensky_note) {
                                                  paymentMode +=
                                                    " " +
                                                    this.state.globalLang
                                                      .label_note +
                                                    " - " +
                                                    obj.greensky_note;
                                                }
                                              } else if (
                                                obj.payment_mode === "check"
                                              ) {
                                                paymentMode =
                                                  this.state.salesLang
                                                    .frontdesk_cheque;

                                                if (
                                                  obj.cheque_no !== "" &&
                                                  obj.cheque_no !== null
                                                ) {
                                                  paymentMode +=
                                                    " #" + obj.cheque_no;
                                                }
                                              } else if (
                                                obj.payment_mode === "gift_card"
                                              ) {
                                                paymentMode = "Gift Card";

                                                if (
                                                  obj.gift_card !== "" &&
                                                  obj.gift_card !== null
                                                ) {
                                                  paymentMode +=
                                                    " (" +
                                                    obj.gift_card
                                                      .redemption_code +
                                                    ")";
                                                }
                                              } else if (
                                                obj.payment_mode === "repeat_md"
                                              ) {
                                                paymentMode =
                                                  "RepeatMD Payment";
                                              } else {
                                                paymentMode =
                                                  capitalizeFirstLetter(
                                                    obj.payment_mode,
                                                  );
                                              }

                                              return (
                                                <tr key={idx}>
                                                  <td
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#777777",
                                                        padding: "10px 0px",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      {paymentMode}
                                                    </div>
                                                  </td>
                                                  <td
                                                    align="right"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #dddddd",
                                                      padding: "10px 0px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontSize: "13px",
                                                        color: "#777777",
                                                        textTransform:
                                                          "uppercase",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {numberFormat(
                                                        obj.total_amount,
                                                        "currency",
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            },
                                          )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    ""
                                  )}
                                  {refunds.length > 0 && (
                                    <table
                                      border={0}
                                      cellPadding={10}
                                      cellSpacing={0}
                                      width={700}
                                      style={{
                                        marginTop: "25px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                padding: "10px 0px",
                                              }}
                                            >
                                              {this.state.globalLang.inv_refund}
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dddddd",
                                            }}
                                            align="right"
                                          >
                                            <div
                                              style={{
                                                fontSize: "13px",
                                                color: "#000000",
                                                textTransform: "uppercase",
                                                textAlign: "right",
                                                padding: "10px 0px",
                                              }}
                                            ></div>
                                          </td>
                                        </tr>
                                        {refunds.map((refund, index) => (
                                          <tr
                                            key={`refund-${refund.amount}-${index}`}
                                          >
                                            <td>
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  color: "#777777",
                                                  padding: "10px 0px",
                                                }}
                                              >
                                                {refund.paymentMode}
                                                <br />
                                                {
                                                  this.state.globalLang
                                                    .inv_refund_reason
                                                }
                                                : {refund.reason}
                                              </div>
                                            </td>
                                            <td
                                              align="right"
                                              style={{ padding: "10px 0px" }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: "13px",
                                                  color: "#777777",
                                                  textTransform: "uppercase",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {refund.amount}
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}

                                  {this.state.invoiceData &&
                                    this.state.invoiceData.patientSignature && (
                                      <table
                                        border={0}
                                        cellPadding={10}
                                        cellSpacing={0}
                                        width={700}
                                        style={{
                                          marginTop: "0px",
                                          borderTop: "1px solid #dddddd",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{ padding: "20px 10px" }}
                                              width="400"
                                            >
                                              <div
                                                style={{
                                                  fontSize: "13px",
                                                  color: "#777777",
                                                  width: "400px",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  src={
                                                    this.state.invoiceData
                                                      .patientSignature
                                                  }
                                                  style={{
                                                    width: "300px",
                                                    display: "inline-block",
                                                  }}
                                                />
                                                <br />
                                                <b>
                                                  (
                                                  {this.state.invoiceData &&
                                                    this.state.invoiceData
                                                      .invoice_data &&
                                                    this.state.invoiceData
                                                      .invoice_data.patient &&
                                                    displayName(
                                                      this.state.invoiceData
                                                        .invoice_data.patient,
                                                    )}
                                                  )
                                                </b>
                                              </div>
                                            </td>
                                            <td width="400">&nbsp;</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    )}

                                  <table
                                    border={0}
                                    cellPadding={10}
                                    cellSpacing={0}
                                    width={700}
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "20px",
                                      borderTop: "1px solid #dddddd",
                                      borderBottom: "1px solid #dddddd",
                                    }}
                                  >
                                    <tbody>
                                      {(this.state.invoiceData?.invoice_data
                                        ?.invoice_status === "draft" ||
                                        this.state.invoiceData?.invoice_data
                                          ?.invoice_status ===
                                          "partial paid") && (
                                        <tr>
                                          <td>
                                            <div
                                              className="text-dark fs-5 m-t-25 m-b-10"
                                              style={{
                                                color: "black",
                                                fontWeight: 600,
                                              }}
                                            >
                                              Balance Due
                                            </div>
                                          </td>
                                          <td align="right">
                                            <div
                                              className="text-dark pull-right fs-5 m-t-25 m-b-10"
                                              style={{
                                                color: "black",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {this.state.invoiceData
                                                ?.invoice_data?.pos_transaction
                                                ? numberFormat(
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .total_amount -
                                                      this.state.invoiceData
                                                        .invoice_data
                                                        .pos_transaction
                                                        .total_amount,
                                                    "currency",
                                                  )
                                                : numberFormat(
                                                    this.state.invoiceData
                                                      .invoice_data
                                                      .total_amount,
                                                    "currency",
                                                  )}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td style={{ padding: "20px 10px" }}>
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              color: "#777777",
                                              whiteSpace: "pre-line",
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                              this.state.invoiceData
                                                .invoice_text &&
                                              this.state.invoiceData
                                                .invoice_text}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table
                                    border={0}
                                    cellPadding={10}
                                    cellSpacing={0}
                                    width={700}
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td width={400}>
                                          <div
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: 600,
                                              color: "#000000",
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .clinic
                                              ? capitalizeFirstLetter(
                                                  shortenClinicName(
                                                    this.state.invoiceData
                                                      .invoice_data.clinic
                                                      .clinic_name,
                                                  ),
                                                )
                                              : ""}
                                          </div>
                                        </td>
                                        <td width={400} align="right">
                                          <div
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                              color: "#777777",
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.state.invoiceData &&
                                            this.state.invoiceData
                                              .invoice_data &&
                                            this.state.invoiceData.invoice_data
                                              .clinic
                                              ? capitalizeFirstLetter(
                                                  this.state.invoiceData
                                                    .invoice_data.clinic
                                                    .contact_no,
                                                )
                                              : ""}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </center>
              </div>
            </div>
            <div className="footer-static invoice-footer">
              {this.state.invoiceData?.invoice_data?.invoice_status ===
                "draft" && (
                <DeleteInvoice
                  invoiceId={this.state.invoiceData?.invoice_data?.id}
                  patientId={this.state.invoiceData?.invoice_data?.patient?.id}
                  takePaymentRoute={`/sales/checkout-invoice/${this.state.invoiceData.invoice_data.id}`}
                />
              )}
              {this.state.invoiceData?.invoice_data?.invoice_status ===
                "pending" && (
                <CancelPendingInvoice
                  invoiceId={this.state.invoiceData?.invoice_data?.id}
                  isMerger={this.state.invoiceData?.invoice_data?.is_merger}
                  refetchInvoiceDetails={() =>
                    this.fetchInvoiceDetails(
                      this.state.invoiceID,
                      this.state.clientID,
                    )
                  }
                />
              )}
              {!["draft", "pending"].includes(
                this.state.invoiceData?.invoice_data?.invoice_status,
              ) && (
                <div className="dropdown pull-right m-l-5">
                  <button
                    className="line-btn no-margin"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.state.globalLang.inv_more_info}
                    <i className="fas fa-angle-down" />
                  </button>
                  <ul className="dropdown-menu">
                    <li onClick={() => this.printOrDownloadInvoice(0)}>
                      <a>{this.state.globalLang.label_download}</a>
                    </li>
                    <li onClick={() => this.printOrDownloadInvoice(1)}>
                      <a>{this.state.globalLang.inv_email_to_client}</a>
                    </li>
                    <li onClick={() => this.printInvoice()}>
                      <a>{this.state.globalLang.inv_print}</a>
                    </li>
                  </ul>
                </div>
              )}
              {refundOptions === "paid" &&
                !this.isInvoiceMergeRefund() &&
                showHideRefundBtn === false &&
                this.canRefund() && (
                  <a
                    onClick={() => this.showRefundModal()}
                    className="blue-btn pull-right"
                  >
                    {this.state.globalLang.inv_issue_refund}
                  </a>
                )}
              {refundOptions === "paid" &&
                !this.isInvoiceMergeRefund() &&
                showHideRefundBtn && (
                  <a
                    onClick={() => {
                      this.setState({ showIssueConfirmationModal: true });
                    }}
                    className="blue-btn pull-right"
                  >
                    {this.state.globalLang.inv_issue_refund}
                  </a>
                )}
              {this.isInvoiceMergeRefund() &&
                this.invoiceMergeRefundOptions().length === 1 && (
                  <a
                    onClick={() =>
                      this.refundMergedInvoiceByType(
                        this.invoiceMergeRefundOptions()[0],
                      )
                    }
                    className="blue-btn pull-right"
                  >
                    {this.state.globalLang.inv_issue_refund}
                  </a>
                )}
              {this.isInvoiceMergeRefund() &&
                this.invoiceMergeRefundOptions().length > 1 && (
                  <div className="dropdown pull-right m-l-5">
                    <button
                      className="line-btn no-margin"
                      type="button"
                      id="dropdownMenu1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.globalLang.inv_issue_refund}
                      <i className="fas fa-angle-down" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      style={{ width: "fit-content" }}
                    >
                      {this.invoiceMergeRefundOptions().map(
                        (refundOption, index) => (
                          <li key={`refund-option-${index}`}>
                            <a
                              onClick={() =>
                                this.refundMergedInvoiceByType(refundOption)
                              }
                            >
                              {this.state.globalLang.inv_issue_refund +
                                " - " +
                                refundOption.title}
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              {showPaymentHistory && (
                <a
                  onClick={() => this.showPaymentHistory()}
                  className="blue-btn pull-right"
                >
                  {this.state.globalLang.inv_payment_history}
                </a>
              )}

              {showVoidButton && this.state.showLoader === false && (
                <a
                  className="blue-btn pull-right"
                  onClick={() => this.showRefundAndVoidOptions()}
                >
                  {this.state.globalLang.inv_void}
                </a>
              )}
              {this.state.invoiceData?.invoice_data?.invoice_status ===
                "partial paid" && (
                <a
                  className="blue-btn pull-right"
                  onClick={() => this.redirectToPayment()}
                >
                  Take Payment
                </a>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showIssueConfirmationModal === true ? "overlay" : ""
          }
        ></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.showIssueConfirmationModal === true
              ? "modal fade in displayBlock"
              : "modal fade no-display"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({ showIssueConfirmationModal: false });
                  }}
                >
                  ×
                </button>
                <h4 className="modal-title">
                  {this.state.globalLang.delete_confirmation}
                </h4>
              </div>
              <div className="modal-body add-patient-form filter-patient">
                {this.state.globalLang.inv_are_you_sure_you_want_to_refund}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ showIssueConfirmationModal: false });
                    }}
                  >
                    {this.state.globalLang.label_no}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={() =>
                      this.isInvoiceMergeRefund()
                        ? this.refundMembershipInvoice()
                        : this.refundIssue()
                    }
                  >
                    {this.state.globalLang.label_yes}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.InvoiceReducer.action === "GET_INVOICE_DETAILS") {
    if (state.InvoiceReducer.data.status === 200) {
      returnState.invoiceData = state.InvoiceReducer.data;
      returnState.tipsPerItem =
        state.InvoiceReducer.data.data?.invoice_data?.pos_invoice_items.reduce(
          (acc, item) => {
            return {
              ...acc,
              [item.id]: item.tip_amount_per_item.toFixed(2),
            };
          },
          {},
        );
    }
  }

  if (state.InvoiceReducer.action === "GET_PAYMENT_HISTORY") {
    returnState.historyData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "VOID_INVOICE") {
    returnState.voidData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "EMAIL_AND_DOWNLOAD_INVOICE") {
    returnState.sendAndDownloadInvoiceData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "GET_REFUND_DETAILS") {
    returnState.getRefundData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "ISSUE_REFUND") {
    returnState.payRefundData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "UPDATE_USER_IN_INVOICE") {
    returnState.updateInvoiceUserData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "SAVE_AND_SEND_EMAIL") {
    returnState.saveAndSendData = state.InvoiceReducer.data;
  }

  if (state.InvoiceReducer.action === "MONTHLY_MEMBERSHIP_ISSUE_REFUND") {
    returnState.memberShipRefundTimestamp = new Date();
  }

  if (state.InvoiceReducer.action === "EMPTY_DATA") {
    return {};
  }

  if (state.InvoiceReducer.action === "VOID_REFUND_INVOICE") {
    returnState.voidAndRefundData = state.InvoiceReducer.data;
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getInvoiceDetails,
      getPaymentHistory,
      sendAndDownloadInvoice,
      sendAndDownloadRefundReceipt,
      getRefundDetails,
      payRefund,
      changeUserID,
      exportEmptyData,
      saveAndSendEmail,
      refundIssue,
      voidAndRefund,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
