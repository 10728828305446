import { useFormik } from "formik";
import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import classes from "./AnswerText.module.scss";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { squashSpaces } from "../../../../../../utilities/general";

export function AnswerText({
  isRequired,
  initialValues,
  onSubmit,
  renderFooter,
}) {
  const { tCommon } = useAppTranslation.Common();

  const validationSchema = yup.object({
    text: yup.lazy(() => {
      const schema = yup.string().trim();
      if (isRequired) {
        return schema.required();
      }
      return schema;
    }),
  });

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    initialValues: {
      text: squashSpaces(initialValues.text) || "",
    },
  });

  const isError = errors.text && touched.text;

  const onFinishLater = () => {
    onSubmit(values, { saveForLater: true });
  };

  return (
    <div className={classes.root}>
      <Textarea
        name="text"
        value={values.text}
        onChange={handleChange}
        placeholder={tCommon("fillPatientQuestionnaire.textAnswerPlaceholder")}
      />
      {renderFooter(handleSubmit, isError, onFinishLater)}
    </div>
  );
}

AnswerText.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
};
