import React, { useEffect, useState } from "react";
import cx from "clsx";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styles from "./styles.module.scss";
import commonStyles from "../../Table.module.scss";
import { DragIcon } from "./shared/DragIcon/DragIcon";
import {
  DEFAULT_PRESS_DELAY,
  DEFAULT_TABLE_WIDTH,
  DRAGGABLE_COL,
  TABLE_DOM_ID,
} from "./config";
import { getColWidth } from "./utilities";

var getSortableRow = (draggable) => {
  const Base = ({ children, className }) => (
    <div className={cx(styles.row, commonStyles.row, className)}>
      {children}
    </div>
  );
  return draggable ? SortableElement(Base) : Base;
};

var SortableWrap = SortableContainer(({ children, className }) => (
  <div className={className}>{children}</div>
));

/**
 * @typedef {{
 *    nonDraggable?: boolean;
 * }} Row
 *
 * @template {Row} T
 *
 * @param {{
 *    data: Array<T>;
 *    cols: Array<{
 *      data: React.ReactNode;
 *      accessor: string;
 *      className?: string;
 *      headColClassName?: string;
 *      width?: number;
 *    }>;
 *    keyExtractor?: (row: any) => string | number;
 *    colClassName?: string;
 *    className?: string;
 *    headClassName?: string;
 *    headColClassName?: string;
 *    onSortEnd?: (args: { oldIndex: number, newIndex: number}) => void;
 *    pressDelay?: number;
 *    rowClassName?: string;
 *    onRowClick?: (row: any, col: any) => void;
 *    preventRowClickForAccessors?: string[];
 *    nonDraggableRowKeys?: (string | number)[];
 * }} props
 */
export function Sortable({
  data,
  cols,
  keyExtractor,
  colClassName,
  className,
  headClassName,
  headColClassName,
  onSortEnd,
  pressDelay,
  rowClassName,
  onRowClick,
  preventRowClickForAccessors,
  nonDraggableRowKeys,
}) {
  var [tableHeight, setTableHeight] = useState(DEFAULT_TABLE_WIDTH);

  // -----

  var preventClickAccessors_ = [
    ...(preventRowClickForAccessors || []),
    DRAGGABLE_COL.accessor,
  ];

  const data_ = (data || []).map((x) => ({
    ...x,
    [DRAGGABLE_COL.accessor]: <DragIcon />,
  }));

  var cols_ = [DRAGGABLE_COL, ...cols];

  var nonDraggableRows = data_.filter(
    (x) => x.nonDraggable || nonDraggableRowKeys?.includes(keyExtractor_(x)),
  );

  var draggableRows = data_.filter((x) => !x.nonDraggable);

  // -----

  var keyExtractor_ = keyExtractor || ((row) => row.key);

  var handleColClick = (row, col) => {
    if (!preventClickAccessors_.includes(col.accessor) && onRowClick) {
      onRowClick(row, col);
    }
  };

  // -----

  useEffect(() => {
    const tableSize = document.querySelector(`#${TABLE_DOM_ID}`);
    tableSize && data?.length > 0
      ? setTableHeight(tableSize.clientHeight + 20)
      : setTableHeight(DEFAULT_TABLE_WIDTH);
  }, [data?.length]);

  // -----

  var renderRows = (rows, draggable = true) => {
    const Row = getSortableRow(draggable);

    return rows.map((row, index) => {
      var key = keyExtractor_(row);

      return (
        <Row
          key={key}
          index={index}
          className={cx(
            rowClassName,
            {
              [commonStyles.rowClickable]: Boolean(onRowClick),
            },
            row.className,
          )}
        >
          {cols_.map((col) => {
            const isDragCell = col.accessor === DRAGGABLE_COL.accessor;

            return (
              <div
                role={onRowClick ? "button" : undefined}
                key={`${key}-${col.accessor?.toString()}`}
                style={{
                  minWidth: col.width || getColWidth(cols.length, isDragCell),
                }}
                className={cx(
                  commonStyles.column,
                  styles.col,
                  {
                    [styles.nonDraggable]: !draggable && isDragCell,
                  },
                  colClassName,
                  col.className,
                )}
                onClick={() => handleColClick(row, col)}
                onKeyDown={() => handleColClick(row, col)}
              >
                {row[col.accessor]}
              </div>
            );
          })}
        </Row>
      );
    });
  };

  return (
    <div style={{ height: tableHeight }} className={cx(styles.root, className)}>
      <div className={styles.inner}>
        <div id={TABLE_DOM_ID} className={styles.table}>
          <div className={cx(styles.head, commonStyles.head, headClassName)}>
            {cols_.map((col, index) => (
              <div
                style={{
                  minWidth:
                    col.width ||
                    getColWidth(
                      cols.length,
                      col.accessor === DRAGGABLE_COL.accessor,
                    ),
                }}
                key={index}
                className={cx(
                  styles.col,
                  styles.headCol,
                  commonStyles.headColumn,
                  colClassName,
                  headColClassName,
                  col.className,
                  col.headColClassName,
                )}
              >
                {col.data}
              </div>
            ))}
          </div>
          {renderRows(nonDraggableRows, false)}
          <SortableWrap
            pressDelay={pressDelay || DEFAULT_PRESS_DELAY}
            onSortEnd={({ oldIndex, newIndex }) => {
              const nonDraggableCount = nonDraggableRows?.length || 0;
              onSortEnd({
                oldIndex: oldIndex + nonDraggableCount,
                newIndex: newIndex + nonDraggableCount,
              });
            }}
          >
            {renderRows(draggableRows)}
          </SortableWrap>
        </div>
      </div>
    </div>
  );
}
