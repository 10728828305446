import React, { useState } from "react";
import { useCheckProcedure } from "../hooks/useCheckProcedure";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { history } from "../../../history";

const CheckProcedureTraceability = ({ procedureId }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: message } = useCheckProcedure(procedureId, {
    select: (data) => data.data.message,
    onSuccess: (data) => {
      if (data) {
        setShowModal(true);
      }
    },
  });

  return showModal ? (
    <ConfirmModal
      isOpen
      onCancel={() => history.goBack()}
      onConfirm={() => setShowModal(false)}
      confirmTitle={"Acknowledge"}
    >
      {message}
    </ConfirmModal>
  ) : (
    <></>
  );
};

export default CheckProcedureTraceability;
