import React from "react";
import classes from "./styles.module.scss";

export function DragIcon() {
  return (
    <div className={classes.root}>
      <span />
      <span />
    </div>
  );
}
