import React from "react";
import { useFormik } from "formik";
import { Trans } from "react-i18next";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../shared/Select/Select";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Button } from "../../../../../shared/Button/Button";
import { useDeleteCategory } from "../hooks/useDeleteCategory";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";
import styles from "../scss/chartFilters.module.scss";

const UNCATEGORIZED = { label: "Uncategorized", value: null };

const DeleteCategoryModal = ({
  selectedCategoryId,
  onClose,
  categories,
  isGettingCategories,
}) => {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const { mutate, isLoading } = useDeleteCategory(selectedCategoryId, {
    onSuccess: (data) => uiNotification.success(data.data.message),
    onError: (error) => uiNotification.error(error.response.data.message),
  });

  const { values, setFieldValue } = useFormik({
    initialValues: {
      selectedCategory: UNCATEGORIZED,
    },
  });

  const options =
    categories
      ?.map((category) => {
        if (category.id !== selectedCategoryId) {
          return { label: category.name, value: category.id };
        }
      })
      .filter(Boolean) || [];

  return (
    <Modal
      isOpen
      onClose={onClose}
      header={
        <Modal.Title>
          {tSettings("clinicalDocumentation.chartFilters.deleteModal.title")}
        </Modal.Title>
      }
      footer={
        <>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              mutate(
                { new_category_id: values.selectedCategory.value },
                {
                  onSuccess: onClose,
                },
              );
            }}
            leftAdornment={
              isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
            isDisabled={isLoading}
          >
            {values.selectedCategory.value
              ? tSettings(
                  "clinicalDocumentation.chartFilters.deleteModal.deleteAndReassign",
                )
              : tSettings(
                  "clinicalDocumentation.chartFilters.deleteModal.delete",
                )}
          </Button>
          <Button onClick={onClose} size="small" variant="outlined">
            {tCommon("label.cancel")}
          </Button>
        </>
      }
      contentClassName={styles.deleteModalContent}
      footerClassName={styles.deleteModalFooter}
    >
      <Trans
        i18nKey={tSettings(
          "clinicalDocumentation.chartFilters.deleteModal.content",
        )}
      />
      <div>
        <InputLabel>{tCommon("label.newCategory")}</InputLabel>
        <Select
          size={"small"}
          options={[UNCATEGORIZED, ...options.filter((x) => x.value !== 0)]}
          onChange={(options) => setFieldValue("selectedCategory", options)}
          value={values.selectedCategory}
          isLoading={isGettingCategories}
        />
      </div>
      {}
    </Modal>
  );
};

export default DeleteCategoryModal;
