import React from "react";
import cx from "clsx";
import styles from "./scss/membershipbadge.module.scss";

export default class MembershipBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color || "#F5CB42",
    };
  }

  render() {
    return (
      <div
        className={cx({
          [styles.onHoldContainer]: this.props.onHold,
        })}
      >
        {Boolean(this.props.onHold) && (
          <div className={styles.obliqueLineWrap}>
            <div className={styles.obliqueLine} />
          </div>
        )}
        <span
          className={cx(this.props.displayPage, "membership-bedge-block")}
          style={{ color: this.props.color }}
        >
          <span
            className="membership-bedge-circle"
            style={{ borderColor: this.props.color, color: this.props.color }}
          >
            <i className="membership-bedge-icon fa fa-star"> </i>
          </span>
        </span>
      </div>
    );
  }
}
